<template>
  <header class="top_header">
    <nav class="navbar navbar-expand-xxl navbar-dark">
      <div class="container">
        <div class="align-items-center d-flex">
          <button
            v-on:click="toggleMenu"
            id="closemenu"
            class="navbar-toggler p-0 border-0 bg-transparent"
            v-bind:class="{ collapsed: isSelected }"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#HeaderNavbarNavDarkDropdown"
            aria-controls="HeaderNavbarNavDarkDropdown"
            :aria-expanded="active ? true : false"
            aria-label="Toggle navigation"
          >
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </button>
          <span class="text-dark font-weight-700 font-20 ms-2">Menu</span>
        </div>

        <router-link class="navbar-brand m-0" to="/">
          <div v-if="logo_url">
            <img
              class="img-fluid"
              v-bind:src="siteURL + logo_url?.logo_url"
              alt="header logo"
            />
          </div>
          <div v-else>
            <img
              class="img-fluid"
              src="../../assets/img/header_logo.png"
              alt="header logo"
            />
          </div>
        </router-link>
        <div class="text-white header_link">
          <ul class="d-flex justify-content-end list-style-none p-0 mb-0 mt-xs-3 mt-sm-3">
            <li class="d-xs-none">
              <a href="#">
                <img src="../../assets/img/search_icon.png" alt="search" />
              </a>
            </li>

            <li v-if="isuserAdmin">
              <span class="ms-2" id="admin"
                ><a
                  target="_blank"
                  class="btn btn-teplate-main btn-sm"
                  :href="this.adminCMSURL + 'users/tlogin/' + getTloginToken"
                  >ADMIN CMS</a
                ></span
              >
            </li>

            <li v-if="userLoggedin === true" class="ms-4 ms-xs-3">
              <span id="myaccount"
                ><router-link to="/account" @click="refreshPage()">MANAGE ACCOUNT</router-link></span
              >
            </li>

            <li class="ms-4 ms-xs-3">
              <div v-if="userLoggedin === true">
                <a @click.prevent="logout()" id="logout">LOG OUT</a>
              </div>
              <div v-else>
                <a
                  style="cursor: pointer"
                  data-bs-toggle="modal"
                  id="loggedinuser"
                  data-bs-target="#userlogin"
                >
                  <img src="../../assets/img/user_icon.png" alt="user" />
                </a>
              </div>
            </li>
            <li class="ms-4 ms-xs-3">
              <div v-if="userLoggedin !== true">
                <a style="cursor: pointer" href="#/register"
                  ><img src="../../assets/img/user_register.png" alt="user"
                /></a>
              </div>
            </li>
            <li class="ms-4 ms-xs-3">
              <router-link class="position-relative" to="/cart"
                ><img src="../../assets/img/cart_icon.png" alt="cart" />

                <span :id="showCartcount > 0 ? 'cartitems' : ''">{{
                  showCartcount > 0 ? showCartcount : ""
                }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <div
          class="collapse navbar-collapse"
          :class="{ show: isShow }"
          id="HeaderNavbarNavDarkDropdown"
        >
          <!-- <div v-html="renderMenu"></div> -->
          <ul class="navbar-nav pb-4 pe-4 ps-4 pt-4 flex-column w-100">
            <li
              v-for="menus in renderMenu"
              v-bind:key="menus"
              :class="menus.havingChildmenus == 1 ? 'nav-item dropdown' : 'nav-item click_on_item'"
            >
              <a
                v-if="menus.parentId == 0 && menus.domain_id == 2"
                :class="
                  menus.havingChildmenus == 1 ? 'nav-link dropdown-toggle d-block w-100' : 'd-block w-100 nav-link '
                "
                :href="menus.slug"
                id="navbarDarkDropdownMenuLink"
                :role="menus.havingChildmenus == 1 ? 'button' : ''"
                :data-bs-toggle="menus.havingChildmenus == 1 ? 'dropdown' : ''"
                :aria-expanded="menus.havingChildmenus == 1 ? 'false' : ''"
              >
                {{ menus.title }} 
              </a>
              <ul
                class="dropdown-menu bg-transparent border-0 pt-0 mt-0"
                aria-labelledby="navbarDarkDropdownMenuLink"
              >
                <li v-for="(childItem, childIndex) in menus.childmenus" :key="childIndex">
                  <a
                    class="dropdown-item d-block w-100"
                    @click="closeMenu()"
                    :href="baseURL + childItem.slug"
                  >
                    {{ childItem.title }}
                  </a>
                </li>
              </ul>
            </li>
            <!-- <li class="nav-item">
              <router-link to="/pages/printing" class="nav-link">Printing</router-link>
            </li> -->
          </ul>
        </div>
      </div>
    </nav>

    <div v-if="showComponent">
      <AsyncPartialComponent />
    </div>
    <!--Banner Section----->
  </header>

  <!----Defing Routing view in order to route to be worked--->
  <router-view></router-view>
  <!----Defing Routing view in order to route to be worked--->

  <!---Popup Model for Usre Register-->
  <UserLogin />
  <!---Popup Model for Usre Register-->
</template>
<script>
import {
  GET_HOME_PAGE_DATA_ACTION,
  GET_USER_STORE_DATA_GETTER,
  CLEAR_UP_LOGIN_ACTION,
  CLEAR_UP_LOGIN_FLAG_ACTION,
  CLEAR_USER_STORE_ACTION,
  DELETE_ALL_ITEMS_CART_ACTION,
  CLEAR_CART_DATA_ACTION,
  CLEAR_USERINFO_ACTION,
} from "@/store/storeconstants";
import $ from "jquery";
import "bootstrap";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { mapActions, mapGetters } from "vuex";
import { useStore } from "vuex";
import { onMounted } from "vue";
import UserLogin from "../UserLogin.vue";

import { defineAsyncComponent, ref } from "vue";

const AsyncPartialComponent = defineAsyncComponent(
  () => import("../ChatWidget.vue") // Path to your partial component
);
export default {
  name: "HeaderItem",
  components: {
    UserLogin,
    AsyncPartialComponent,
  },
  data: function () {
    return {
      contactUsPageLink : process.env.VUE_APP_BASE_URL_LOCAL + "/pages/contact-us",
      loginModalOpen : localStorage.getItem("loginModalOpen") != null ? localStorage.getItem("loginModalOpen") : "false",
      allmenuitems: "",
      havingChildmenus: false,
      menuItems: {},
      menuData: [],
      responseData: {},
      chatyes: false,
      isSelected: false,
      active: false,
      isShow: false,
      webUrl: process.env.VUE_APP_BASE_URL_WEB,
      isuserLoggedin: [],
      adminCMSURL: null,
      loadme: ref(false),
    };
  },

  setup() {
    const store = useStore();
    //let enviroments = process.env;

    let baseURL = process.env.VUE_APP_BASE_URL_LOCAL;
    let siteURL = process.env.VUE_APP_BASE_URL_WEB;

    let logo_url = store.state.homeData.home.header;

    let user_Loggedin = store.state.auth.userLoggedin;

    const showComponent = ref(false);

    const loadComponent = () => {
      showComponent.value = true;
    };

    onMounted(() => {
      user_Loggedin;
      siteURL;
    });
    return {
      store,
      user_Loggedin,
      logo_url,
      baseURL,
      siteURL,
      showComponent,
      loadComponent,
    };
  },
  computed: {
    renderMenu() {
      let menu = [];
      this.menuData.map((e) => {
        menu.push({
          title: e.title,
          slug: e.slug,
          menu_id: e.id,
          domain_id: e.domain_id,
          havingChildmenus: e.hasMenu,
          childmenus: e.sub_menu,
          parentId: e.parent_id,
        });
      });

      return menu;
    },
    userLoggedin() {
      let userloggedin =
        // eslint-disable-next-line no-prototype-builtins
        this.store.state.auth.userLoggedin;

      return userloggedin;
    },
    showCartcount() {
      let cartitems =
        this.store.state.cartData.cart != undefined
          ? this.store.state.cartData.cart.length
          : 0;

      return cartitems;
    },
    isuserAdmin() {
      let getuserstore =
        this.store.state.auth.UserStore.UserStore !== undefined
          ? this.store.state.auth.UserStore.UserStore
          : {};
      // eslint-disable-next-line no-prototype-builtins
      let checkadmin = [];
      $.each(getuserstore, (i, e) => {
        if (i === "role_scopes") {
          $.each(e, (i1, e1) => {
            checkadmin.push(e1);
          });
        }
      });
      let isAdmin = checkadmin.includes("admin") ? true : false;

      return isAdmin;
    },
    getTloginToken() {
      let getuserstore =
        this.store.state.auth.UserStore.UserStore !== undefined
          ? this.store.state.auth.UserStore.UserStore
          : {};
      // eslint-disable-next-line no-prototype-builtins
      let tLogin = getuserstore.data.tlogin !== "" ? getuserstore.data.tlogin : null;
      return tLogin;
    },
    ...mapGetters([GET_USER_STORE_DATA_GETTER]),
    watch: {
      GET_USER_STORE_DATA_GETTER,
    },
  },

  methods: {
    // this code for account page reload start
    refreshPage(){
      setTimeout(() => {
        location.reload();
      }, 2000);
    },
     // this code for account page reload start
    ...mapActions("homeData", {
      homePageData: GET_HOME_PAGE_DATA_ACTION,
    }),
    ...mapActions("auth", {
      loggedOut: CLEAR_UP_LOGIN_ACTION,
    }),
    ...mapActions("auth", {
      loggedoutflag: CLEAR_UP_LOGIN_FLAG_ACTION,
    }),

    ...mapActions("auth", {
      userstoreEmpty: CLEAR_USER_STORE_ACTION,
    }),

    ...mapActions("cartData", {
      deletedAllItem: DELETE_ALL_ITEMS_CART_ACTION,
    }),

    ...mapActions("cartData", {
      clearCartItems: CLEAR_CART_DATA_ACTION,
    }),
    ...mapActions("userInfo", {
      clearUserInfo: CLEAR_USERINFO_ACTION,
    }),

    closeMenu() {
      document.getElementById("closemenu").click();
    },

    logout() {
      let confirmhere = confirm("Are you sure you want to log out?");
      if (confirmhere == true) {
        this.loggedOut({}).then(() => {
            this.loggedoutflag({});
            this.userstoreEmpty({});
            //location.reload();
            //location.href = "/#/";

            /***clear cart items ****/
            this.deletedAllItem();
            this.clearCartItems({});
            /***clear cart items ****/

            /*****clear userinfo******/
            this.clearUserInfo({});
            /*****clear userinfo******/

            /***clear all localstorage ***/
            this.$storageclear();
            /***clear all localstorage */
          
            toast.success("Logged out succesfully", { autoClose: 2000 });
            this.$router.push("/");
            localStorage.clear();
            this.loginModalOpen = 'true'; 
            localStorage.setItem("loginModalOpen", this.loginModalOpen) 
            setTimeout(() => {
                window.location.reload(); 
            }, 100);
             
            
        });
      }
    },

    toggleMenu() {
      this.isSelected = !this.isSelected;
      this.active = !this.active;
      this.isShow = !this.isShow;
    },
  },

  created() {
    
    this.emitter.on("closeMenu", this.toggleMenu);

    if (localStorage.getItem("isRegistered") == null) {
      localStorage.setItem("isRegistered", false);
    } else {
      localStorage.setItem("isRegistered", false);
    }
    this.$axios; //mount global axious variable
    this.$baseurl; //mount global baseurl variable
    this.$axios
      .get(
        process.env.VUE_APP_BASE_URL +
          "getMenuForHeaderAndFooter?position=top&domain_id=2"
      )
      .then((res) => {
        let mymenuData = res.data.data;
        mymenuData.forEach((e) => {
          let getmenuId = e.id;
          let domainId = e.domain_id;
          let parentId = e.parent_id;
          let title = e.title;
          let sub_menu = e.sub_menu;
          let hasMenu = e.hasMenu;
          let slug = e.slug;
          this.menuData.push({
            domain_id: domainId,
            menu_id: getmenuId,
            parent_id: parentId,
            title: title,
            sub_menu: sub_menu,
            hasMenu: hasMenu,
            slug: slug,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // Get Home Page Data with Header And Footer
    this.adminCMSURL = "https://admincms.hollywoodstudios.org/";

    this.homePageData();
  },

  mounted() {
    // let checkclosemenu = document.getElementById("closemenu");

    /*****FOR INVALID AUTHENTICATION *************/
    if (localStorage.getItem("Unauthenticated") !== null) {
      this.loggedOut({}).then(() => {
        this.loggedoutflag({});
        this.userstoreEmpty({});
      });
      this.userLoggedin;
      localStorage.removeItem("Unauthenticated");
      if (localStorage.getItem("loggedout") !== null) {
        localStorage.removeItem("loggedout");
        if (this.userLoggedin == false) {
          setTimeout(() => {
            if (document.getElementById("loggedinuser")) {
              document.getElementById("loggedinuser").click();
            }
          }, 1500);
        }
      }

      // if (this.userLoggedin == false) {
      //   document.getElementById("loggedinuser").click();
      // }
      toast.error("Your session is expired please login again!", {
        autoClose: 3500,
      });
    }

    /*****FOR INVALID AUTHENTICATION *************/
  },

  updated() {
    setTimeout(() => {
      if (localStorage.getItem("loginModalOpen") == "true") {
          document.getElementById("loggedinuser").click();
      } 
    }, 2500);
    
    let getbtnclose = document.getElementById("getbtnclose");
      if (getbtnclose !== null) {
        getbtnclose.addEventListener("click", () => {
          localStorage.setItem("loginModalOpen", "false")
      });
    }

    let btn = document.getElementById("chatus");
    if (btn !== null) {
      btn.addEventListener("click", () => {
        $("#chatload").html("<p class='ms-5 ps-4 h6'>Loading Chat....</p>");
        window.scrollBy(100, 100);
        setTimeout(() => {
          $("#chatload").html("");
        }, 2000);
        document.getElementById("chathere").click();
        //this.loadComponent;
      });
    }

   


    /*****FOR INVALID AUTHENTICATION *************/
    if (localStorage.getItem("Unauthenticated") !== null) {
      this.loggedOut({}).then(() => {
        this.loggedoutflag({});
        this.userstoreEmpty({});
      });
      this.userLoggedin;
      localStorage.removeItem("Unauthenticated");
      if (localStorage.getItem("loggedout") !== null) {
        localStorage.removeItem("loggedout");
        if (this.userLoggedin == false) {
          setTimeout(() => {
            document.getElementById("loggedinuser").click();
          }, 1500);
        }
      }

      // if (this.userLoggedin == false) {
      //   document.getElementById("loggedinuser").click();
      // }
      toast.error("Your session is expired please login again!", {
        autoClose: 3500,
      });
    }

    /*****FOR INVALID AUTHENTICATION *************/

    /****FOR LOGGED OUT ******/
    if (localStorage.getItem("loggedout") !== null) {
      //toast.success("Logged out successfully", { autoClose: 3500 });
      localStorage.removeItem("loggedout");
      if (this.userLoggedin == false) {
        setTimeout(() => {
           localStorage.setItem("loginModalOpen", 'false')
        }, 1500);
      }
    }
    /****FOR LOGGED OUT *******/
  },

};
 
 
document.addEventListener("click", (evt) => {
    let closemenuId  = document.getElementById("closemenu")
    let targetElement = evt.target;
    const navbar = document.querySelector('.navbar-collapse');
    const navbarToggler = document.querySelector('.navbar-toggle');
    do {
       $(".click_on_item").on("click", function(){
            navbar.classList.remove('show');
        })
        // open menu when click is on toggle button and menu does not have the 'show' class
        if (targetElement == navbarToggler && ! navbar.classList.contains('show')) {
            navbar.classList.add('show');  
            return;
        }else if (targetElement == navbar) {  // do nothing when clicked in navbar
            return;
        }
        // Go up the DOM
        targetElement = targetElement.parentNode;
    } while (targetElement);
        // when clicked outside of navbar
        navbar.classList.remove('show');
        closemenuId.setAttribute("aria-expanded", "false");
         
});

</script>
<style scoped>
ul > li > a {
  text-transform: uppercase;
}
span#cartitems {
  background: #dc3545;
  border-radius: 100%;
  width: 16px !important;
  font-size: 10px;
  height: 16px;
  line-height: 17px;
  color: #fff;
  position: absolute;
  text-align: center;
  right: -12px;
  top: -7px;
}
#logout {
  font-size: 14px;
  cursor: pointer;
  color: #555555;
  margin-right: 15px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
}
#myaccount a {
  font-size: 14px;
  color: #555555;
  margin-right: 15px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
}

#admin a {
  background: #ffffff !important;
  color: #38a7bb !important;
  border-color: #38a7bb;
  padding: 3px 3px 3px 3px !important;
  line-height: 1.4em !important;
}
#admin a:hover {
  border-radius: 0px 15px 0px 15px;
  background: #38a7bb !important;
  color: #ffffff !important;
}
</style>
