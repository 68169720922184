    <template>
        <div class="container">
            
            <div class="row"> 
                <div class="mb-5 mt-5 m-auto col-sm-8 col-sm-push-2 form-box animated fadeInDown" data-animate="fadeInDown"
                    style="opacity: 0">
                    <form @submit.prevent="ContactUsSubmit()" method="post" id="ConatctUs"> 
                            
                        <div class="form-bottom">
                            <div class="row">
                                
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input class="form-control" placeholder="Enter Name" type="text"
                                            autofocus required v-model.trim="formData.name" />
                                    </div>
                                </div>
    
                                <div class="col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input class="form-control" placeholder="E-Mail Address"
                                            type="email" autofocus required v-model.trim="formData.email" /> 
                                    </div>
                                </div>

                                <div class="col-sm-12 col-xs-12">
                                    <div class="form-group">
                                        <label>Message</label>
                                        <textarea rows="4" class="form-control h-100" placeholder="Enter Message" v-model.trim="formData.message" autofocus required></textarea>
                                    </div>
                                </div>

                            </div>
                        </div> 

                        <div class="mt-5">
                            <button type="submit" class="btn btn-lg btn-info pull-right">
                                Submit
                            </button>
                        </div> 
                         
                    </form>
                </div> 
                
            </div>
        </div>
  </template>
  
  <script>
    import axios from "axios"; 
    import { toast } from "vue3-toastify";
    import "vue3-toastify/dist/index.css";
    export default {
        name: "ContactUs",

        data() {
            return {
                formData: {
                    name: "",
                    email: "",
                    message: "", 
                },
            };
        },

        methods:{
            ContactUsSubmit() {
                let formData = new FormData();
                formData.append("email", this.formData.email);
                formData.append("name", this.formData.name);
                formData.append("message", this.formData.message);
                axios.post( process.env.VUE_APP_BASE_URL + "contactUs", formData).then(res => {
                    if (res.status == 200) {
                        this.formData = ""; 
                        toast.success(res.data.message, {
                            autoClose: 2500,
                        });
                    }
                }).catch(error => {
                    if (error.response.status === 422 || error.response.status === 500) {
                        error.response.data.message
                        toast.error(error.response.data.message, {
                            autoClose: 2500,
                        });
                    }
                });

            },
        }
        
    };
  </script>
  