<template>
    <InnerPageBanner :data="Base_Url ? Base_Url : '/img/innner_page_banner.png'" :page="page" />

    <div class="container mb-5 mt-5">
        <div class="row">
            <div class="col-sm-12 col-md-3 mb-3">
                <ProductNavbar />
            </div>

            <div class="col-sm-12 col-md-9">
                <div class="row" v-if="renderProducts != 'not found'">
                    <div v-html="renderProducts.product.long_description"></div>

                    <div class="col-sm-12 col-md-7 mb-3">
                        <Carousel :items-to-show="1" :wrap-around="false" @init="handleInit" @slide-start="handleSlideStart"
                            v-bind="settings" :breakpoints="breakpoints" class="productCarousel"
                            v-if="renderProducts.files.length">
                            <Slide v-for="slide in renderProducts.files" :key="slide">
                                <div class="carousel__item printing_services_owl_carousel h-100">
                                    <div class="card w-100 h-100">
                                        <div class="slider_img h-100">
                                            <img class="w-100 h-100" :src="this.$baseurlForImages +
                                                'files/scale/image/600x400/' +
                                                slide.file_name
                                                " alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Slide>

                            <template #addons>
                                <Navigation v-if="renderProducts.files.length" />
                            </template>
                        </Carousel>
                        <div class="slider_img h-100" v-else>
                            <img class="w-100" src="./../../assets/img/product_img.png" alt="" />
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-5 mb-3">
                        <div class="printing_from_side">
                            <nav style="display: none" aria-label="breadcrumb">
                                <ol class="breadcrumb align-items-center mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="#"
                                            class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none">Home</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"
                                            class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none">Note
                                            pads</a>
                                    </li>
                                    <li
                                        class="breadcrumb-item font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none">
                                        5.5" X 8.5" 50 Note pads
                                    </li>
                                </ol>
                            </nav>
                            <p id="productmaintitle">
                                <strong class="font-30 font-weight-700"  v-html="renderProducts.product.title ? renderProducts.product.title : ''"></strong>
                                    <!-- <strong class="font-30 font-weight-700">{{
                                    renderProducts.product.title != ""
                                        ? renderProducts.product.title
                                        : "loading..."
                                    }}</strong> -->
                            </p>
                            <p class="font-12 font-weight-400 font-family-Roboto text-capitalize text-grey mt-2">
                                SKU: {{ renderProducts.product.sku }}
                            </p>

                            <div class="" v-if="this.$store.state.product.SingleProduct.product.add_to_cart == 1">
                                <p class="font-12 font-weight-400 font-family-Roboto text-capitalize text-grey mb-1">
                                    Upload into template
                                </p>
                                <p class="font-12 font-weight-400 font-family-Roboto text-capitalize text-grey">
                                    accepted file types: jpg, tif, png, single Page PDF only
                                </p>
                                  
                                <form @submit.prevent="formSubmit($event)" action="" ref="form" enctype="multipart/form-data">
                                    <div>
    
                                        <div v-if="this.$store.state.product.SingleProduct?.data?.product_type?.large_format && this.$store.state.product.SingleProduct?.data?.product_type?.large_format == 1
                                            ">
                                            <p>
                                                <scan v-if="this.$store.state.product.SingleProduct?.data?.product_type?.show_feets == 1">
                                                    <input id="show_in_inches" name="show_dimensions_in_inches" type="checkbox"
                                                        value="1" @change="onChangeWidth($event)" />
                                                    <label class="form-label font-14 font-weight-700 text-dark"
                                                        for="show_in_inches"> Show Dimensions
                                                        In Inches</label>
                                                </scan>
                                                <br/>
    
                                                <input ref="productid" name="data[Checkout][product_id]" type="hidden" :value="this.$store.state.product.SingleProduct.product.id" />
    
                                                <label for="CheckoutWidth">Width</label>
                                                <select ref="getWidthFields" data-label="Width" data-idval="width"
                                                    required="true" rel="po-width:drpdn" :id="`CheckoutWidth`"
                                                    :data-index="`CheckoutDrpdn-${this.$store.state.product.SingleProduct?.product_options?.length + 1}`"
                                                    :data-product-id="this.$store.state.product.SingleProduct?.id"
                                                    :name="`data[Checkout][option][${this.$store.state.product.SingleProduct?.product_options?.length + 1}][value]`"
                                                    :data-id="`data[Checkout][option][${this.$store.state.product.SingleProduct?.product_options?.length + 1}][id]`"
                                                    :class="`allfield font-10 font-family-Roboto font-weight-400 form-select trigger-change large-product`"
                                                    @change.prevent="getProductOptionValue($event)">
    
                                                    <option v-for="item in heightAndWidth"
                                                        :data-id="`${item.key}:${this.$store.state.product.SingleProduct?.product_options?.length + 1}`"
                                                        :data-val="`${item.value}`" :key="item.key" :value="item.key">
                                                        {{ item.value }}
                                                    </option>
    
    
                                                </select>
    
                                                <input :name="`data[Checkout][option][${this.$store.state.product.SingleProduct?.product_options?.length + 1
                                                    }][value]`" type="hidden" value="width" />
                                                <br/>
                                                <label for="CheckoutHeight">Height</label>
                                                <select required="true" :id="`CheckoutHeight`" data-label="Height" data-idval="height" :data-index="`CheckoutDrpdn-${this.$store.state.product.SingleProduct?.product_options?.length + 2
                                                        }`" rel="po-height:drpdn" :data-product-id="this.$store.state.product.SingleProduct?.id
                                                        " :name="`data[Checkout][option][${this.$store.state.product.SingleProduct
                                                        ?.product_options?.length + 2
                                                        }][value]`" :data-id="`data[Checkout][option][${this.$store.state.product.SingleProduct
                                                        ?.product_options?.length + 2
                                                        }][id]`" :class="`allfield font-10 font-family-Roboto font-weight-400 form-select trigger-change large-product`"
                                                        @change.prevent="getProductOptionValue($event)">
    
                                                        <option v-for="item in heightAndWidth" :data-id="`${item.key}:${this.$store.state.product.SingleProduct
                                                            ?.product_options?.length + 2
                                                            }`" :data-val="`${item.value}`" :key="item.key" :value="item.key">
                                                            {{ item.value }}
                                                        </option>
    
                                                </select>
                                                <input :name="`data[Checkout][option][${this.$store.state.product.SingleProduct?.product_options?.length + 2}][value]`" type="hidden" value="height" />
                                            </p>
                                        </div>
    
                                        <div v-for="(product_options, index) in this.$store.state.product.SingleProduct.product_options"
                                            :key="index">
                                            <input ref="productid" name="data[Checkout][product_id]" type="hidden"
                                                :value="product_options.Product.id" />
                                            <div class="mb-3">
                                                <div v-if="product_options.ProductOption.option_type != 5">
                                                    <label class="form-label font-14 font-weight-700 text-dark">{{
                                                        product_options.ProductOption.name
                                                    }}</label>
                                                </div>
    
                                                <div v-if="product_options.ProductOption.option_type != 5 && product_options.ProductOption.option_type != 3" >
                                                    <select ref="getFields" :required="product_options.ProductOption.option_required == 1 ? true : false " :label="product_options.ProductOption.name" :id="`CheckoutDrpdn-${index}`" :data-index="`CheckoutDrpdn-${index}`" :data-product-id="product_options.Product.id" :data-change-template="product_options.ProductOption.change_template === 1 ? 1 : 0 " :rel="'po-' + product_options.ProductOption.id + ':' + product_options.ProductOption.option_type " :name="`data[Checkout][option][${index}][value]`" :data-id="`data[Checkout][option][${index}][id]`" :class="`allfield font-10 font-family-Roboto font-weight-400 form-select trigger-change ${'attr-' + product_options.ProductOption.name.toLowerCase().replace(' ', '-')}`" @change.prevent="getProductOptionValue($event)">
    
                                                        <option value="">Please Select</option>
                                                        <option :data-id="`${ProductOptionValue.product_option_id}:${index}`"
                                                            :data-val="`${ProductOptionValue.id}`"
                                                            v-for="ProductOptionValue in product_options.ProductOptionValue"
                                                            :key="ProductOptionValue.id"
                                                            :value="`{id:${ProductOptionValue.product_option_id}:${index}, val:${ProductOptionValue.id}}`">
                                                            {{ ProductOptionValue.choice_description }}
                                                        </option>
    
                                                    </select>
                                                </div>

                                                <div v-if="product_options.ProductOption.option_type == 3">
                                                    {{ product_options.ProductOption.product_option_value[0].prefill }}
                                                    <input 
                                                    :data-index="`CheckoutDrpdn-${index}`" 
                                                    :data-product-id="product_options.Product.id"
                                                    :required="product_options.ProductOption.option_required == 1 ? true : false " 
                                                    :label="product_options.ProductOption.name"
                                                    :rel="'po-' + product_options.ProductOption.id + ':' + product_options.ProductOption.option_type "
                                                    :name="`data[Checkout][option][${index}][value]`"
                                                    :data-id="`data[Checkout][option][${index}][id]`"
                                                    type="text" 
                                                    :value="product_options.ProductOption.product_option_value[0].prefill"
                                                    class="font-10 font-family-Roboto font-weight-400 form-control"/>

                                                    <!-- <select ref="getFields" :required="product_options.ProductOption.option_required == 1 ? true : false " :label="product_options.ProductOption.name" :id="`CheckoutDrpdn-${index}`" :data-index="`CheckoutDrpdn-${index}`" :data-product-id="product_options.Product.id" :data-change-template="product_options.ProductOption.change_template === 1 ? 1 : 0 " :rel="'po-' + product_options.ProductOption.id + ':' + product_options.ProductOption.option_type " :name="`data[Checkout][option][${index}][value]`" :data-id="`data[Checkout][option][${index}][id]`" :class="`allfield font-10 font-family-Roboto font-weight-400 form-select trigger-change ${'attr-' + product_options.ProductOption.name.toLowerCase().replace(' ', '-')}`" @change.prevent="getProductOptionValue($event)">
    
                                                        <option value="">Please Select</option>
                                                        <option :data-id="`${ProductOptionValue.product_option_id}:${index}`"
                                                            :data-val="`${ProductOptionValue.id}`"
                                                            v-for="ProductOptionValue in product_options.ProductOptionValue"
                                                            :key="ProductOptionValue.id"
                                                            :value="`{id:${ProductOptionValue.product_option_id}:${index}, val:${ProductOptionValue.id}}`">
                                                            {{ ProductOptionValue.choice_description }}
                                                        </option>
    
                                                    </select> -->
                                                </div>

    
                                                <div class="mt-2 mb-2 text text-danger"></div>
                                                <input :id="`CheckoutDetails${product_options.ProductOption.option_type}`"
                                                    :name="`data[Checkout][option][${index}][id]`" type="hidden" value="775" />
                                            </div>
                                        </div>
                                        
                                    </div>
    
                                    <div v-if="this.$store.state.product.SingleProduct?.data?.product_type
                                        ?.large_format == 1 ">
                                        <label>Quantity (same image)</label>
                                        <select required="true" data-label="Quantity" data-idval="qty" rel="po-qty:drpdn"
                                            :id="`CheckoutQuantity`" :data-index="`CheckoutDrpdn-${this.incrementor + 1}`"
                                            :data-id="`data[Checkout][option][${this.incrementor + 1
                                                }][id]`" :data-product-id="this.$store.state.product.SingleProduct?.id" :name="`data[Checkout][option][${this.incrementor + 1}][value]`" :class="`allfield font-10 font-family-Roboto font-weight-400 form-select trigger-change large-product`"
                                            @change.prevent="getProductOptionValue($event)">
                                            <option v-for="item in this.getQtyArr()"
                                                :data-id="`${item}:${this.incrementor + 1}`" :data-val="`${item}`" :key="item"
                                                :value="item">
                                                {{ item }}
                                            </option>
                                        </select>
    
                                        <input :name="`data[Checkout][option][${this.incrementor + 1
                                            }][value]`" :id="`CheckoutQuantity${this.incrementor + 1}`" type="hidden" value="width" />
                                        <!--- For height-->
                                        <br />
                                    </div>
    
    
                                    <div class="mb-3">
                                        <label class="form-label font-14 font-weight-700 text-dark">File Name</label>
                                        <input ref="filename" name="data[Checkout][filename]" required type="text"
                                            class="font-10 font-family-Roboto font-weight-400 form-control fileupload"
                                            placeholder="Enter file name" />
                                        <div class="text text-danger"></div>
                                    </div>
                                    
                                    <!-- <input
                                        ref="pro_price"
                                        type="hidden"
                                        name="data[Checkout][price]"
                                        id="product-price1"
                                        value="0.00"
                                        />
                                        <div
                                        id="total-templates"
                                        rel="0"
                                        class="hidden"
                                        data-changed="0"
                                     ></div> -->
    
                                    <div class="white_bg_btn">
                                            <!-- <input class="btn btn-primary font-13 me-sm-3 p-0 w-100" id="add-to-cart-btn"
                                        v-on:click="validateInputs()" type="button" value="Add to cart"> -->
    
                                        <input class="btn btn-primary font-13 me-sm-3 p-0 w-100" id="add-to-cart-btn"
                                            type="submit" value="Add to cart" />
                                    </div>
    
                                    <div style="display: none" ref="carterror"
                                        class="mt-3 mb-2 alert alert-danger text-center h6"></div>
                                    <div style="display: none" id="cartadd"
                                        class="mt-3 mb-2 alert alert-success text-center h6"></div>
    
                                    <div class="input">
                                        <div>
                                            <div id="product-option-image" v-html="productoptionimage"></div>
                                            <br /><br />
                                            <div class="input">
                                                <label><strong>SKU:</strong>
                                                    {{ renderProducts.product.sku }}</label>
                                            </div>
    
                                            <div v-for="(options, index) in renderProducts.product_options" v-bind:key="index">
                                                <div v-if="options.ProductOption.option_type == 5">
                                                    <input type="hidden" required id="template-for-product"  class="template-for-product" :rel-key="index"  :name="'data[Checkout][option][' + index + '][value]'" />
                                                    <div class="mt-4 mb-2" id="template-uploader">
                                                        <strong>Upload into template</strong> <br />
                                                        <div class="somehilights">
                                                            accepted file types: jpg, tif, png,single Page PDF
                                                            only
                                                        </div>
                                                        <input :name="'data[Checkout][option][' + index + '][id]'" type="hidden"
                                                            class="template-for-product" :rel-key="index"
                                                            id="template-for-product-id"
                                                            :value="options.ProductOption.id" /><br />
    
                                                        <span v-if="options.Product.show_uploader != 1">
                                                            <a class="btn btn-info" id="artwork-upld-link"
                                                                data-bs-toggle="modal" @click.prevent="openCanvas($event)"
                                                                data-bs-target="#canvaspopup">Click Here</a>
                                                            <div
                                                                v-if="getArtwork_Details !== undefined && getArtwork_Details !== undefined">
                                                                <div class="mt-3 mb-2"
                                                                    :style="getArtwork_Details.file_name == '' && getArtwork_Details.file_path == '' ? 'display: none' : 'display:block'"
                                                                    id="artworkfiles">
                                                                    <div v-if="getArtwork_Details.file_path">
                                                                        <h6>
                                                                            <a target="_blank"
                                                                                :href="getArtwork_Details.file_path">Download
                                                                                Artwork</a>
                                                                        </h6>
                                                                    </div>
                                                                    <div style="display: none"
                                                                        class="mt-2 mb-2 artworkerror alert alert-danger"></div>
                                                                </div>
                                                            </div>
                                                        </span>
    
                                                        <span v-else>
                                                            <div class="classicuploader"> <br /> use
                                                                <a @click="uploadFile($event)" id="show-classic-uploader"
                                                                    class="btn btn-secondary p-1">CLASSIC UPLOADER</a>
                                                            </div>
                                                        </span>
                                                        
                                                        <!-- Artwork Modal Start -->
                                                        <div ref="modal" class="modal fade" id="canvaspopup" tabindex="-1"
                                                            role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                        <h4 class="artwrk modal-title">
                                                                            Upload artwork |
                                                                            <div v-if="artWorkData.name">
                                                                                {{ artWorkData.name }}
                                                                            </div>
                                                                        </h4>
                                                                        <button type="button" class="close" id="artworkclose"
                                                                            data-dismiss="modal" aria-label="Close"></button>
                                                                        <div style="display: none"
                                                                            class="mt-4 mb-4 artwork-error alert alert-danger">
                                                                        </div>
    
                                                                        <div style="display: none"
                                                                            class="mt-4 mb-4 artwork-success alert alert-success">
                                                                        </div>
                                                                        <button type="button" id="closeart"
                                                                            class="close font-30" data-bs-dismiss="modal"
                                                                            aria-hidden="true">
                                                                            &times;
                                                                        </button>
                                                                    </div>
    
                                                                    <div class="modal-body clearfix">
                                                                        <div id="mycanvas">
                                                                            <p id="loader" class="hidden">
                                                                                Loading....
                                                                            </p>
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <!-- Option Buttons Start -->
                                                                                    <div class="col-lg-2">
                                                                                        <div v-if="typeof getoptionmap !==
                                                                                            'undefined'
                                                                                            ">
                                                                                            <div v-if="typeof getoptionmap !==
                                                                                                'undefined' &&
                                                                                                getoptionmap.template_config.includes(
                                                                                                    'front'
                                                                                                )
                                                                                                ">
                                                                                                <div class="form-group">
                                                                                                    <div class="art-lft"
                                                                                                        style="height: 175px">
                                                                                                        <div class="artwork-selection selected"
                                                                                                            rel="front">
                                                                                                            <a
                                                                                                                class="btn btn-primary">Front
                                                                                                                Template</a>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="upld-artwork-small mt-4">
                                                                                                            <div v-if="typeof getoptionmap !==
                                                                                                                'undefined' &&
                                                                                                                getoptionmap.template_config.includes(
                                                                                                                    'front'
                                                                                                                )
                                                                                                                ">
                                                                                                                <img id="artworkimgside"
                                                                                                                    style="
                                                                                                                    display: none;
                                                                                                                    width: 148px;
                                                                                                                    height: 100px;
                                                                                                                    " :src="typeof frontframework !==
                                                                                                                        'undefined'
                                                                                                                        ? frontframework
                                                                                                                        : ''
                                                                                                                        " />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
    
                                                                                        <!---if front option is available--->
                                                                                        <div v-if="typeof getoptionmap !==
                                                                                            'undefined' &&
                                                                                            getoptionmap.template_config.includes(
                                                                                                'back'
                                                                                            )
                                                                                            ">
                                                                                            <div class="form-group"
                                                                                                id="art-lft-back">
                                                                                                <br />
                                                                                                <div class="art-lft">
                                                                                                    <div class="artwork-selection"
                                                                                                        rel="back">
                                                                                                        <a
                                                                                                            class="btn btn-primary">Back
                                                                                                            Template</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---if front option is available--->
    
                                                                                        <!---if back option is available--->
                                                                                        <div v-if="typeof getoptionmap !==
                                                                                            'undefined' &&
                                                                                            getoptionmap.template_config.includes(
                                                                                                'front-uv'
                                                                                            )
                                                                                            ">
                                                                                            <div class="form-group front_uv">
                                                                                                <div class="art-lft">
                                                                                                    <div class="artwork-selection"
                                                                                                        rel="frontuv">
                                                                                                        <a
                                                                                                            class="btn btn-primary">Front
                                                                                                            UV</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---if back option is available--->
    
                                                                                        <!---if back-uv option is available--->
                                                                                        <div v-if="typeof getoptionmap !==
                                                                                            'undefined' &&
                                                                                            getoptionmap.template_config.includes(
                                                                                                'back-uv'
                                                                                            )
                                                                                            ">
                                                                                            <div class="form-group back_uv">
                                                                                                <div class="art-lft">
                                                                                                    <div class="artwork-selection"
                                                                                                        rel="backuv">
                                                                                                        <a
                                                                                                            class="btn btn-primary">Back
                                                                                                            UV</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---if back-uv option is available--->
    
                                                                                        <!---if matte-dull option is available--->
                                                                                        <div v-if="typeof getoptionmap !==
                                                                                            'undefined' &&
                                                                                            getoptionmap.template_config.includes(
                                                                                                'matte-dull'
                                                                                            )
                                                                                            ">
                                                                                            <div class="form-group matte-dull">
                                                                                                <div class="art-lft">
                                                                                                    <div class="artwork-selection"
                                                                                                        rel="matte-dull">
                                                                                                        <a
                                                                                                            class="btn btn-primary">Matte
                                                                                                            Dull</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---if matte-dull option is available--->
    
                                                                                        <!---if back-mask option is available--->
                                                                                        <div v-if="typeof getoptionmap !==
                                                                                            'undefined' &&
                                                                                            getoptionmap.template_config.includes(
                                                                                                'back-mask'
                                                                                            )
                                                                                            ">
                                                                                            <div class="form-group back-mask">
                                                                                                <div class="art-lft">
                                                                                                    <div class="artwork-selection"
                                                                                                        rel="back-mask">
                                                                                                        <a
                                                                                                            class="btn btn-primary">Back
                                                                                                            Mask</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---if back-mask option is available--->
    
                                                                                        <div class="others-uplds"></div>
                                                                                        <div>
                                                                                            <br />
    
                                                                                            <div v-if="artWorkData.instruction !== ''
                                                                                                ">
                                                                                                <div
                                                                                                    v-html="artWorkData.instruction">
                                                                                                </div>
                                                                                            </div>
                                                                                            <!--- Template Instructions--->
                                                                                        </div>
                                                                                    </div>
                                                                                    <!-- Option Buttons End -->
    
                                                                                    <div class="col-lg-10">
                                                                                        <!-- Template Control Panel Start -->
                                                                                        <div
                                                                                            class="panel panel-default panel-style">
                                                                                            <div class="panel-heading">
                                                                                                Tools
                                                                                            </div>
                                                                                            <div class="panel-body clearfix">
                                                                                                <div
                                                                                                    class="pull-left marg10 br-rt">
                                                                                                    <div class="tool-title">
                                                                                                        Rotate Template
                                                                                                    </div>
                                                                                                    <div class="tool-body">
                                                                                                        <i class="fa fa-retweet btn btn-danger blk"
                                                                                                            id="rotate-template"
                                                                                                            title="Rotate  template"></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="pull-left marg10 br-rt">
                                                                                                    <div style="cursor: pointer"
                                                                                                        class="tool-title">
                                                                                                        Upload Artwork
                                                                                                    </div>
                                                                                                    <div class="tool-body">
                                                                                                        <form method="post"
                                                                                                            enctype="multipart/form-data"
                                                                                                            id="uplfrm">
                                                                                                            <div
                                                                                                                class="fileUpload btn btn-success">
                                                                                                                <i class="fa fa-upload"
                                                                                                                    title="Upload file"></i>
                                                                                                                <input @click="
                                                                                                                    $event.target.value =
                                                                                                                    ''
                                                                                                                    " type="file" class="upload" @change.prevent="
                                                                                                                                                                        uploadArtworkimg(
                                                                                                                                                                            $event
                                                                                                                                                                        )
                                                                                                                                                                        " :data-templateid="artWorkData.template_id
                                                                                                                                                                        ? artWorkData.template_id
                                                                                                                                                                        : ''
                                                                                                                                                                        " :data-width="artWorkData.width
                                                                                                                                                                        ? artWorkData.width
                                                                                                                                                                        : ''
                                                                                                                                                                        " :data-height="artWorkData.height
                                                                                                                                                                        ? artWorkData.height
                                                                                                                                                                        : ''
                                                                                                                                                                        " :data-weight="artWorkData.weight
                                                                                                                                                                        ? artWorkData.weight
                                                                                                                                                                        : ''
                                                                                                                                                                        " :data-ratio="artWorkData.ratio
                                                                                                                                                                        ? artWorkData.ratio
                                                                                                                                                                        : ''
                                                                                                                                                                        " id="image-uploader" name="'data[File][image]'" accept="image/gif, image/jpeg, image/jpg, image/png, image/tiff, application/pdf" />
                                                                                                            </div>
                                                                                                        </form>
                                                                                                    </div>
                                                                                                    <div style="display: none"
                                                                                                        class="upload-progress">
                                                                                                        <img
                                                                                                            src="/img/ajax-loader-sm.gif" />
                                                                                                    </div>
                                                                                                </div>
    
                                                                                                <div class="tool-menu">
                                                                                                    <div
                                                                                                        class="pull-left marg10 br-rt">
                                                                                                        <div class="tool-title">
                                                                                                            Rotate artwork
                                                                                                        </div>
                                                                                                        <div class="tool-body">
                                                                                                            <i class="fa fa-repeat btn btn-info blk"
                                                                                                                id="rotate-art-right"
                                                                                                                title="Rotate clockwise 90 deg"></i>
                                                                                                            <i class="fa fa-undo btn btn-info blk"
                                                                                                                id="rotate-art-left"
                                                                                                                title="Rotate anti-clockwise 90 deg"></i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="pull-left marg10 br-rt">
                                                                                                        <div class="tool-title">
                                                                                                            Scale artwork
                                                                                                        </div>
                                                                                                        <div class="tool-body">
                                                                                                            <div
                                                                                                                class="pull-left up-down">
                                                                                                                <i class="fa fa-chevron-up btn"
                                                                                                                    id="rotate-scale-up"
                                                                                                                    title="Scale up"></i>
                                                                                                                <i class="fa fa-chevron-down btn"
                                                                                                                    id="rotate-scale-down"
                                                                                                                    title="Scale down"></i>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="pull-left">
                                                                                                                <i class="fa fa-arrows btn blk"
                                                                                                                    id="rotate-scale-reset"
                                                                                                                    title="Reset scale"></i>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="pull-left marg10 br-rt">
                                                                                                        <div class="tool-title">
                                                                                                            Move artwork
                                                                                                        </div>
                                                                                                        <div class="tool-body">
                                                                                                            <div
                                                                                                                class="pull-left">
                                                                                                                <i class="fa fa-arrow-left btn blk"
                                                                                                                    id="artwork-move-left"
                                                                                                                    title="Move artwork left"></i>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="pull-left up-down">
                                                                                                                <i class="fa fa-arrow-up btn"
                                                                                                                    id="artwork-move-up"
                                                                                                                    title="Move artwork up"></i>
                                                                                                                <i class="fa fa-arrow-down btn"
                                                                                                                    id="artwork-move-down"
                                                                                                                    title="Move artwork down"></i>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="pull-left">
                                                                                                                <i class="fa fa-arrow-right btn blk"
                                                                                                                    id="artwork-move-right"
                                                                                                                    title="Move artwork right"></i>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="pull-left marg10">
                                                                                                        <div class="tool-title">
                                                                                                            Finalize
                                                                                                        </div>
                                                                                                        <div class="tool-body">
                                                                                                            <div
                                                                                                                class="pull-left marg10 br-rt">
                                                                                                                <div @click.prevent="
                                                                                                                    resetArtworkWorkspace(
                                                                                                                        $event
                                                                                                                    )
                                                                                                                    " class="btn btn-warning" id="reset-artwork">
                                                                                                                    <i
                                                                                                                        class="fa fa-refresh"></i>
                                                                                                                    Reset
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="pull-left marg10 br-rt">
                                                                                                                <div @click.prevent="
                                                                                                                    saveTemplate(
                                                                                                                        (email =
                                                                                                                            'undefined')
                                                                                                                    )
                                                                                                                    " class="btn btn-danger" id="save-template">
                                                                                                                    <i
                                                                                                                        class="fa fa-save"></i>
                                                                                                                    Save
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="pull-left marg10">
                                                                                                                <div @click.prevent="
                                                                                                                    saveTemplateEmail()
                                                                                                                    " class="btn btn-primary" id="email-template">
                                                                                                                    <i
                                                                                                                        class="fa fa-paperclip"></i>
                                                                                                                    Save & Email
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div id="email-details"
                                                                                                                class="hidden">
                                                                                                                <div
                                                                                                                    class="input input-prepend">
                                                                                                                    <span
                                                                                                                        class="add-on">Email</span>
                                                                                                                    <input
                                                                                                                        ref="getemail"
                                                                                                                        class="span2"
                                                                                                                        id="prependedInput"
                                                                                                                        type="text"
                                                                                                                        placeholder="Email" />
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="input">
                                                                                                                    <button
                                                                                                                        @click.prevent="
                                                                                                                            saveTemplate(
                                                                                                                                this.$refs
                                                                                                                                    .getemail[0]
                                                                                                                                    .value
                                                                                                                            )
                                                                                                                            " class="btn btn-success" id="send-email">
                                                                                                                        Send
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div style="display: none"
                                                                                                            class="mt-4 mb-2 erroremail alert alert-danger">
                                                                                                        </div>
                                                                                                        <div style="display: none"
                                                                                                            class="mt-4 mb-2 successemail alert alert-success">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
    
                                                                                            <em class="d-block mb-2 ms-4">
                                                                                                <small>Note: - kindly upload
                                                                                                    document
                                                                                                    max with dimensions (width:
                                                                                                    {{ artWorkData.width }}px
                                                                                                    and
                                                                                                    height:
                                                                                                    {{ artWorkData.height }}
                                                                                                    px)in
                                                                                                    case of PDF it should be
                                                                                                    single
                                                                                                    pager.
                                                                                                </small>
                                                                                            </em>
                                                                                        </div>
                                                                                        <!-- Template Control Panel End -->
    
                                                                                        <!-- Artwork Show Here Start -->
                                                                                        <div id="workspace">
                                                                                            <!---- front image Start--->
                                                                                            <div v-if="typeof getoptionmap !==
                                                                                                'undefined' &&
                                                                                                getoptionmap.template_config.includes(
                                                                                                    'front'
                                                                                                )
                                                                                                " id="main-front" class="main-artwork hidden">
                                                                                                <div class="template-bg"></div>
                                                                                                <div id="main-front-template"
                                                                                                    class="template">
                                                                                                    <img :src="typeof getoptionmap.path
                                                                                                        .front !== 'undefined'
                                                                                                        ? getoptionmap.path.front
                                                                                                        : ''
                                                                                                        " />
                                                                                                </div>
                                                                                                <div class="user-artwork">
                                                                                                    <!-- <img id="artworkimg" :src="typeof frontframework !== 'undefined' ? frontframework: ''"
                                                                                                /> -->
                                                                                                </div>
                                                                                            </div>
                                                                                            <!----front image End--->
    
                                                                                            <!----back image Start--->
                                                                                            <div v-if="typeof getoptionmap !==
                                                                                                'undefined' &&
                                                                                                getoptionmap.template_config.includes(
                                                                                                    'back'
                                                                                                )
                                                                                                " id="main-back" class="main-artwork hidden">
                                                                                                <div class="template-bg"></div>
                                                                                                <div id="main-back-template"
                                                                                                    class="template">
                                                                                                    <img :src="typeof getoptionmap.path
                                                                                                        .back !== 'undefined'
                                                                                                        ? getoptionmap.path.back
                                                                                                        : ''
                                                                                                        " />
                                                                                                </div>
                                                                                                <div class="user-artwork">
                                                                                                    <!-- <img id="artworkimg" :src=" typeof frontframework !== 'undefined' ? frontframework: ''"/> -->
                                                                                                </div>
                                                                                            </div>
                                                                                            <!----back image End--->
    
                                                                                            <!----frontuv image Start--->
                                                                                            <div v-if="typeof getoptionmap !==
                                                                                                'undefined' &&
                                                                                                getoptionmap.template_config.includes(
                                                                                                    'front-uv'
                                                                                                )
                                                                                                " id="main-frontuv" class="main-artwork hidden">
                                                                                                <div class="template-bg"></div>
                                                                                                <div id="main-frontuv-template"
                                                                                                    class="template">
                                                                                                    <img :src="typeof getoptionmap.path
                                                                                                        .front !== 'undefined'
                                                                                                        ? getoptionmap.path.front
                                                                                                        : ''
                                                                                                        " />
                                                                                                </div>
                                                                                                <div class="user-artwork">
                                                                                                    <!-- <img id="artworkimg" :src=" typeof frontframework !== 'undefined' ? frontframework: ''"/> -->
                                                                                                </div>
                                                                                            </div>
                                                                                            <!----frontuv image End--->
    
                                                                                            <!----backuv image Start--->
                                                                                            <div v-if="typeof getoptionmap !==
                                                                                                'undefined' &&
                                                                                                getoptionmap.template_config.includes(
                                                                                                    'back-uv'
                                                                                                )
                                                                                                " id="main-backuv" class="main-artwork hidden">
                                                                                                <div class="template-bg"></div>
                                                                                                <div id="main-backuv-template"
                                                                                                    class="template">
                                                                                                    <img :src="typeof getoptionmap.path
                                                                                                        .front !== 'undefined'
                                                                                                        ? getoptionmap.path.front
                                                                                                        : ''
                                                                                                        " />
                                                                                                </div>
                                                                                                <div class="user-artwork">
                                                                                                    <!-- <img id="artworkimg" :src=" typeof frontframework !== 'undefined' ? frontframework: ''"/> -->
                                                                                                </div>
                                                                                            </div>
                                                                                            <!----backuv image End--->
    
                                                                                            <!----mattedull image Start--->
                                                                                            <div v-if="typeof getoptionmap !==
                                                                                                'undefined' &&
                                                                                                getoptionmap.template_config.includes(
                                                                                                    'matte-dull'
                                                                                                )
                                                                                                " id="main-mattedull" class="main-artwork hidden">
                                                                                                <div class="template-bg"></div>
                                                                                                <div id="main-mattedull-template"
                                                                                                    class="template">
                                                                                                    <img :src="typeof getoptionmap.path
                                                                                                        .front !== 'undefined'
                                                                                                        ? getoptionmap.path.front
                                                                                                        : ''
                                                                                                        " />
                                                                                                </div>
                                                                                                <div class="user-artwork">
                                                                                                    <!-- <img id="artworkimg" :src=" typeof frontframework !== 'undefined' ? frontframework: ''"/> -->
                                                                                                </div>
                                                                                            </div>
                                                                                            <!----mattedull image End--->
    
                                                                                            <!----frontmask image Start--->
                                                                                            <div v-if="typeof getoptionmap !==
                                                                                                'undefined' &&
                                                                                                getoptionmap.template_config.includes(
                                                                                                    'front-mask'
                                                                                                )
                                                                                                " id="main-frontmask" class="main-artwork hidden">
                                                                                                <div class="template-bg"></div>
                                                                                                <div id="main-frontmask-template"
                                                                                                    class="template">
                                                                                                    <img :src="typeof getoptionmap.path
                                                                                                        .front !== 'undefined'
                                                                                                        ? getoptionmap.path.front
                                                                                                        : ''
                                                                                                        " />
                                                                                                </div>
                                                                                                <div class="user-artwork">
                                                                                                    <!-- <img id="artworkimg" :src=" typeof frontframework !== 'undefined' ? frontframework: ''"/> -->
                                                                                                </div>
                                                                                            </div>
                                                                                            <!----frontmask image End--->
    
                                                                                            <!----backmask image Start--->
                                                                                            <div v-if="typeof getoptionmap !==
                                                                                                'undefined' &&
                                                                                                getoptionmap.template_config.includes(
                                                                                                    'back-mask'
                                                                                                )
                                                                                                " id="main-backmask" class="main-artwork hidden">
                                                                                                <div class="template-bg"></div>
                                                                                                <div id="main-backmask-template"
                                                                                                    class="template">
                                                                                                    <img :src="typeof getoptionmap.path
                                                                                                        .front !== 'undefined'
                                                                                                        ? getoptionmap.path.front
                                                                                                        : ''
                                                                                                        " />
                                                                                                </div>
                                                                                                <div class="user-artwork">
                                                                                                    <!-- <img id="artworkimg" :src=" typeof frontframework !== 'undefined' ? frontframework: ''"/> -->
                                                                                                </div>
                                                                                            </div>
                                                                                            <!----backmask image End--->
                                                                                        </div>
                                                                                        <!-- Artwork Show Here End -->
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Artwork Modal End -->
    
                                                        <!-- <div v-if="options.Product.show_uploader == 1">
                                                            <div id="classic-uploader-div" class="hidden">
                                                                <div id="artwork-options" class="input">
                                                                    <div id="allfiles"></div>
                                                                    <input
                                                                        @change="upload_File($event)"
                                                                        id="fileUploaded"
                                                                        ref="myfiles"
                                                                        class="filer mt-2"
                                                                        accept="image/gif, image/jpeg, image/jpg, image/png, image/tiff, application/pdf, application/postscript, application/eps, application/x-eps, image/eps, image/x-eps, application/illustrator"
                                                                        :name="'data[File][image]'"
                                                                        type="file"
                                                                        multiple="multiple"
                                                                        style=""
                                                                    />
                                                                    <div class="text text-danger"></div>
                                                                    <div
                                                                    class="mt-2 mb-2 row"
                                                                    ref="file_preview"
                                                                    id="filepreview"
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                        </div> -->
    
                                                    </div>
                                                </div>
                                                <!-- <div ref="dynamichtml" v-html="productOption"></div> -->
                                            </div>
    
                                            <!---Large format product image upload-->
                                            <div v-if="this.$store.state.product.SingleProduct?.data?.show_uploader == 1 || (this.$store.state.product.SingleProduct?.data?.product_type?.large_format && this.$store.state.product.SingleProduct?.data?.product_type?.large_format == 1)">
                                                <div id="classic-uploader-div"
                                                    :class="this.$store.state.product.SingleProduct?.data?.product_type?.large_format == 1 ? '' : `hidden`">
                                                    <div id="artwork-options" class="input">
                                                        <div id="allfiles"></div>
                                                        <input @change="upload_File($event)" id="fileUploaded" ref="myfiles"
                                                            class="filer mt-2"
                                                            accept="image/gif, image/jpeg, image/jpg, image/png, image/tiff, application/pdf, application/postscript, application/eps, application/x-eps, image/eps, image/x-eps, application/illustrator"
                                                            :name="'data[File][image]'" type="file" multiple="multiple"
                                                            style="" />
                                                        <div class="text text-danger"></div>
                                                        <div class="mt-2 mb-2 row" ref="file_preview" id="filepreview">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- END -->
    
                                        </div>
                                        <input ref="pro_price" type="hidden" name="data[Checkout][price]" id="product-price1"
                                            value="0.00" />
                                        <div id="total-templates" rel="0" class="hidden" data-changed="0"></div>
    
                                        <p class="font-14 font-weight-400 font-family-Roboto text-capitalize text-grey mt-3 input"
                                            id="enter-zip">
                                            <span id="ship-modal-content">
                                                Get Shipping Estimate:
                                            </span>
    
                                            <span id="ship-modal-link" class="bg-transparent border-0 font-13 text-info"
                                                @click="shippingCalculatorByZip('shipping-calculator')">
                                                <img class="ms-2" src="./../../assets/img/ups_icon.png" alt="" />
                                            </span>
                                        </p>
                                        <div id="shipping-total" class="input">
                                            <select id="element_shipping_cost" name="shipping_cost"
                                                class="fade form-control mb-3"></select>
                                        </div>
                                    </div>
                                </form>
    
                                <div class="border-0 card shadow">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between">
                                            <span class="font-16 font-weight-400">Subtotal</span>
                                            <span ref="product_price" id="product-price" class="font-16 font-weight-700">$ {{
                                                baseSubtotal
                                            }}</span>
                                        </div>
                                        <hr class="custom" />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
                <div class="row" v-else>
                    <div v-if="page">
                        <div class="col-md-12" v-html="page.body_text"></div>
                    </div>
                    <div v-else></div>
                </div>
            </div>
        </div>

        <!----display  ShippingCalculator--->
        <!-- <ShippingCalculator :storeData="userData" /> -->
        <div class="modal fade" id="shipping-calculator">
            <div class="modal-dialog modal-sm modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="Login">Calculate Shipping Estimates</h4>
                        <button type="button" class="close" data-bs-dismiss="modal" id="shipping-calculator-close"
                            aria-hidden="true" @click="closeShippingModal('shipping-calculator')">
                            ×
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="input">
                                <div class="input text col-lg-5">
                                    <label for="UserZip" class="d-block mb-3">Enter your zip code</label>
                                    <input type="text" size="20" name="user_zip" id="UserZip" class="form-control"
                                        autocomplete="off" />
                                    <button class="btn btn-lg btn-primary d-block font-17 mt-3 w-25" id="get_shipping_quote"
                                        @click="get_shipping_quote()">
                                        Go
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!----display   ShippingCalculator--->
    </div>
</template>

<script>
import $ from "jquery";
import "bootstrap";
//import Vue from 'vue';

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import ProductNavbar from "@/components/ProductNavbar.vue";
import InnerPageBanner from "@/components/InnerPageBanner.vue";
import { mapActions } from "vuex";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
// eslint-disable-next-line no-unused-vars
import Store_template from "@/views/AddTemplate/store-templates";
// import ShippingCalculator from "@/components/ShippingCalculator.vue";

//import { Modal } from "usemodal-vue3";
//let isVisible = ref(false);

import {
    GET_CART_DATA_ACTION,
    GET_ATTRIBUTES_DATA_ACTION,
    GET_ARTWORK_DATA_ACTION,
    GET_ARTWORK_UPLOAD_ACTION,
    GET_FRAMEWORK_ACTION,
    SAVE_ARTWORK_ACTION,
    GET_SINGLE_PRODUCT_ACTION,
} from "@/store/storeconstants";

export default {
    name: "PrintingPage",
    components: {
        ProductNavbar,
        InnerPageBanner,
        Carousel,
        Navigation,
        Slide,
        // ShippingCalculator,
        //VueDrawingCanvas,
    },

    data() {
        return {
            heightAndWidth: {},
            data: {
                data: {
                    options: [],
                    Shipping: {},
                    Product: {},
                },
            },
            slides: [],
            currentSlide: 1,
            // carousel settings
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: "center",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: "start",
                },
            },
            product_type: 0,
            min_dimention: 0,
            max_dimention: 0,
            incrementor: 0,
            widthHeightDimentions: "",
            qtyArr: [],
            checkattr: true,
            hasFile: 0,
            productoptionimage: "",
            userZip: "",

            file: {},
            style: {
                left_ratio: "",
                left_width: "",
                main_width: "",
                main_height: "",

                larger_section: "",
                left_height: "",
                artwork_margin_left: "",
                artwork_margin_top: "",
                rotate_margin_top: "",
                rotate_margin_left: "",
            },
            Artwork: {
                files_name: null,
                file_path: null,
            },
            project: {
                left_ratio: null,
                cart: "d",
                template_id: null,
                ratio: 0,
                rotate_margin_top: null,
                rotate_margin_left: null,
                front: {},
                back: {},

                frontuv: {},
                backuv: {},
                mattedull: {},
                frontmask: {},
                backmask: {},
                width: null,
                height: null,
                newwidth: null,
                newheight: null,
                actual_width: null,
                actual_height: null,
                org_width: null,
                org_height: null,
            },
            move: {
                top: 0,
                left: 0,
                width: 0,
                height: 0,
            },

            productData: [],
            Base_Url: "",
            page: [],
            page_details: [],
            banner: "",
            getMyAttr: [],
            product_options: [],
            getoptionmap: {
                template_config: [],
                Artwork: [],
                path: [],
            },
            template_id: null,

            formData: {
                getFields: [],
                filename: "",
                file: [],
            },
            products: {
                singleproduct: null,
            },
            //Api Variables
            product_id: "",
            productOption: "",
            selectedVal: "",
            product: [],
            shipping_details: 0,
            shipObj: new Object(),
            priceObj: new Object(),
            qty: 0,
            org_dimension_data: "",
            AllDataSend: "",
            filename: "",
            baseSubtotal: 0.0,
            storeCartData: "",
            artWorkData: {
                template_id: null,
                name: null,
                width: null,
                height: null,
                weight: null,
                ratio: null,
                front_image: null,
                back_image: null,
                instruction: null,
            },
            product_option_map: [],
            productoptions: [],
            framework: [],
            frontframework: [],
        };
    },

    beforeCreate() {
        console.log("beforeCreate lifecycle hook");
        localStorage.removeItem("element_shipping_cost");
        localStorage.removeItem("element_shipping_cost_key");
        localStorage.removeItem("priceObj");
        this.$store.commit("removeArtworData");
    },

    setup() {
        const store = useStore();
        let prodata = store.state.product;
        const route = useRoute();
        const params = route.params;
        const pagename = params.pagename;

        return {
            pagename,
            prodata,
            route,
        };
    },

    computed: {
        checkAttrb() {
            let attr = this.checkattr;
            return attr;
        },
        renderProducts() {
            let products =
                this.prodata.SingleProduct !== undefined
                    ? this.prodata.SingleProduct
                    : "not found";

            return products;
        },

        getOptionStateMap() {
            let option_state_map =
                localStorage.getItem("product_option_map") !== null
                    ? localStorage.getItem("product_option_map")
                    : "";
            let option_map =
                option_state_map !== "" ? JSON.parse(option_state_map) : "";

            return option_map;
        },

        getProductTemplate() {
            let getpro = this.prodata.SingleProduct.product_options;
            console.log(getpro, "getpro");
            let template_id = 0;
            let map_data = this.getOptionStateMap;

            console.log("e.map_data ..", map_data.changeTemplate);

            template_id = map_data.changeTemplate;
            getpro.map((e) => {
                if (e.ProductOption.option_type == 5) {
                    // this uncomment By MD
                    e.ProductOptionValue.forEach((el) => {
                        console.log(el.template_id, "DDDD ID")
                        if (typeof el === "object") {
                        //    if (el.template_id !== 0) {
                        //       template_id = el.template_id;
                        //     }
                        }
                    });
                    return template_id;
                }
            });

            // console.log(checktemplate.length);
            return template_id;
        },

        getArtworkdetails() {
            let artworkname = this.artWorkData;

            return artworkname;
        },

        getProductTemplateName(template_id) {
            return template_id;
        },
        getArtwork_Details() {
            let artworkfiles =
                localStorage.getItem("artworkFiles") !== ""
                    ? JSON.parse(localStorage.getItem("artworkFiles"))
                    : "";
            let files = {};
            if (artworkfiles != undefined && artworkfiles !== "") {
                let getfilename = artworkfiles.file_name;
                let getfilepath = artworkfiles.file_path;

                files = {
                    file_name: getfilename,
                    file_path: getfilepath,
                };
            } else {
                ///
                /// let files = {};
                let fileData = this.$store.state.product.saveArtwork;
                console.log(fileData, "fileData");

                if (fileData != undefined && fileData !== "") {
                    files = {
                        file_name: fileData.file,
                        file_path: fileData.path,
                    };
                }
            }

            return files;
        },
    },

    created() {
        window.onload = codeAddress;
        function codeAddress() {
            let ArtworkFiledata = localStorage.getItem("artworkFiles");
            if (ArtworkFiledata) {
                localStorage.removeItem("artworkFiles");
            }
        }

        /*****create a blank template of product option maps on page load *****/
        let product_option_map = {
            price: 0.0,
            file: {},
            total_templates: 0,
            template_coating: [],
            hasFile: 0,
            shipReturn: {},
            shipChanged: 0,
            weight: 0.0,
            changeTemplate: 0,
        };
        if (localStorage.getItem("product_option_map") == null) {
            localStorage.setItem(
                "product_option_map",
                JSON.stringify(product_option_map)
            );
        } else {
            localStorage.setItem(
                "product_option_map",
                JSON.stringify(product_option_map)
            );
        }
        /*****create a blank template of product option maps on page load *****/

        let checkurl = this.route.params;
        let pageurl = "";
        console.log(checkurl.pagename, "urlPage");
        if (checkurl.pagename !== "undefined") {
            console.log("onhashchange");
            pageurl = checkurl.pagename;
            console.log(pageurl, "pageurl");
            this.getSingleProduct(pageurl).then((res) => {
                console.log(res, 'res Md Respose')
                if (res.status == 200) {
                   // console.log(res.data.data.product, "getSingleProduct------???-????--");
               
                    ////Set product meta tags //
                        const descEl           = document.querySelector('head meta[name="keywords"]'); 
                        const descTitle        = document.querySelector('head meta[name="title"]');
                        const descdescription  = document.querySelector('head meta[name="description"]');
                        ///console.log('descEl....',descEl); meta_description  description
                        const titleEl = document.querySelector('head title'); 
                        descEl.setAttribute('content',res.data.data.product.meta_keywords);
                        descTitle.setAttribute('content',res.data.data.product?.meta_title?res.data.data.product.meta_title:res.data.data.product.title);
                        descdescription.setAttribute('content',res.data.data.product?.meta_description);
                        titleEl.textContent =res.data.data.product.title;   
                        ///// End ///  

                       this.products.singleproduct = res.data.data;
                }
            })
                .catch((error) => {
                    console.log(error, "Error From getSingleProduct");
                });

            // let _that = this;
            window.onhashchange = function () {
                // _that.getSingleProduct(pageurl);
                window.location.reload();
            };
        }

        this.getPage();
    },

    mounted() {
       

        // console.log(this.heightAndWidth, "MD")
        let checkallselects = document.querySelectorAll(".allfield");
        //let allattr = [];

        // eslint-disable-next-line no-unused-vars
        let allselect = checkallselects.length;

        /****Logic for shoping artwork upload once all attributes are selected */
        // checkallselects.forEach((e) => {
        //   e.addEventListener("change", () => {
        //     let chkval = e.options[e.selectedIndex].value.length;

        //     if (chkval > 0) {
        //       allselect--;
        //     } else {
        //       allselect++;
        //     }
        //     console.log(allselect);
        //   });
        // });
        /****Logic for shoping artwork upload once all attributes are selected */

        // console.log(this.getArtwork_Details);
        this.productData = this.prodata;

        (this.product_options =
            this.$store.state.product.SingleProduct != undefined
                ? [...this.$store.state.product.SingleProduct.product_options]
                : []),
            (this.baseSubtotal = 0.0);
    },

    watch: {
        $route() {
            this.getPage();
        },
    },

    updated() {

        $('.artwork-selection').on('click', function () {
            $('.artwork-selection').removeClass('selected');
            $(this).addClass('selected');
            const active = $(this).attr('rel');
            console.log(active)
            $('.main-artwork').addClass('hidden');
            
            if (active == "matte-dull") {
                $('#main-mattedull').removeClass('hidden');
            }

            if (active == "back-mask") {
                $('#main-backmask').removeClass('hidden');
            }
            $('#main-' + active).removeClass('hidden');
        });

        const CheckoutHeight = document.getElementById("CheckoutHeight")?.options.length
        const CheckoutWidth = document.getElementById("CheckoutWidth")?.options.length
        console.log(CheckoutHeight, CheckoutWidth, "CheckoutHeight", "CheckoutWidth out")

        if (CheckoutHeight <= 0 && CheckoutWidth <= 0) {
            console.log(CheckoutHeight, CheckoutWidth, "CheckoutHeight", "CheckoutWidth in")
            this.heightAndWidth = this.getWidth()
        }

        this.renderProducts; 
    },

    methods: {
        ...mapActions("cartData", {
            getCartData: GET_CART_DATA_ACTION,
        }),

        ...mapActions("product", {
            setAttribute: GET_ATTRIBUTES_DATA_ACTION,
        }),
        ...mapActions("product", {
            getArtWork: GET_ARTWORK_DATA_ACTION,
        }),

        ...mapActions("product", {
            uploadArtWork: GET_ARTWORK_UPLOAD_ACTION,
        }),

        ...mapActions("product", {
            getFramework: GET_FRAMEWORK_ACTION,
        }),

        ...mapActions("product", {
            saveArtwork: SAVE_ARTWORK_ACTION,
        }),

        ...mapActions("product", {
            getSingleProduct: GET_SINGLE_PRODUCT_ACTION,
        }),
        handleSlideStart(data) {
            this.currentSlide = data.currentSlideIndex + 1;
        },

        getQtyArr() {
            let qtyArr = [];
            qtyArr[0] = 0;
            for (
                let i =
                    this.$store.state.product.SingleProduct?.data?.product_type
                        ?.min_order_qty;
                i <=
                this.$store.state.product.SingleProduct?.data?.product_type
                    ?.max_order_qty;
                i++
            ) {
                qtyArr[i] = i;
            }
            ///console.log("qtyArr....", qtyArr);

            return qtyArr;
        },

        onChangeCheckoutWidth(_that) {
            console.log(_that);
            var width = $("#CheckoutWidth").val();
            var height = $("#CheckoutHeight").val();
            var dim = this.widthHeightDimentions.split("x");
            var mindim = parseInt(dim[0]);
            if (parseInt(dim[0]) > parseInt(dim[1])) {
                mindim = parseInt(dim[1]);
            }
            if (height > mindim && width > mindim) {
                alert(
                    "Height should be less than or equal to " +
                    mindim +
                    " inches if width is greater than " +
                    mindim +
                    " inches"
                );
                $("#CheckoutWidth").val(mindim);
            }
        },

        onChangeCheckoutHeight(_that) {
            console.log(_that);
            var width = $("#CheckoutHeight").val();
            var height = $("#CheckoutWidth").val();
            var dim = this.widthHeightDimentions.split("x");
            var mindim = parseInt(dim[0]);
            if (parseInt(dim[0]) > parseInt(dim[1])) {
                mindim = parseInt(dim[1]);
            }
            if (height > mindim && width > mindim) {
                alert(
                    "Height should be less than or equal to " +
                    mindim +
                    " inches if width is greater than " +
                    mindim +
                    " inches"
                );
                $(this).val(mindim);
            }
        },

        onChangeWidth(_that) {
            // $('#show_in_inches').change(function(){
            var selected_width = $("#CheckoutWidth").val();
            var selected_height = $("#CheckoutHeight").val();
            //  console.log(selected_width);
            //console.log(selected_height);
            //products->ProductType->show_feets
            if (
                this.$store.state.product.SingleProduct?.data?.product_type
                    ?.show_feets == 1
            ) {
                this.product_type = "large";

                this.min_dimention = this.$store.state.product.SingleProduct?.data?.product_type?.min_dimention;
                this.max_dimention = this.$store.state.product.SingleProduct?.data?.product_type?.max_dimention;
            }

            if (
                this.$store.state.product.SingleProduct?.data?.product_type
                    ?.max_order_dimentions.length
            ) {
                //$products->ProductType->max_order_dimentions
                this.widthHeightDimentions =
                    this.$store.state.product.SingleProduct?.data?.product_type?.max_order_dimentions.split(
                        "x"
                    );
            }
            this.qtyArr = [0];

            for (
                i = this.$store.state.product.SingleProduct?.data?.min_order_qty;
                i <= this.$store.state.product.SingleProduct?.data?.max_order_qty;
                i++
            ) {
                this.qtyArr[i] = i;
            }
            console.log("this.qtyArr???..", this.qtyArr);

            console.log("Hi....", _that);

            if ($("#show_in_inches").is(":checked")) {
                this.org_dimension_data = $("#CheckoutWidth").html();
                var newhtml = "";
                for (var i = this.min_dimention; i <= this.max_dimention; i++) {
                    // newhtml += '<option value="' + i + '">' + i + '"</option>';
                    let optionId = Number(this.$store.state.product.SingleProduct?.product_options?.length) + 1;
                    newhtml += '<option value="' + i + '" data-id="' + i + ':' + optionId + '" data-val="' + i + '">' + i + '"</option>';
                }
                $("#CheckoutWidth").empty();
                $("#CheckoutWidth").append(newhtml);

                $("#CheckoutHeight").empty();
                $("#CheckoutHeight").append(newhtml);
                $("#CheckoutWidth").val(selected_width);
                $("#CheckoutHeight").val(selected_height);
            } else {
                $("#CheckoutWidth").empty();
                $("#CheckoutWidth").append(this.org_dimension_data);
                $("#CheckoutHeight").empty();
                $("#CheckoutHeight").append(this.org_dimension_data);
                $("#CheckoutWidth").val(selected_width);
                $("#CheckoutHeight").val(selected_height);
            }
            // });
        },

        getWidth() {
            let width_array = [];
            let ProductType = this.$store.state.product.SingleProduct?.data?.product_type;
            for (let i = ProductType?.min_dimention; i <= ProductType?.max_dimention; i++) {
                // console.log("Hello Main",i)
                if (ProductType.show_feets == 1) {
                    width_array.push({ key: i, value: Math.floor(i / 12) + "' " + (i % 12) + '"', });
                } else {
                    width_array.push({ key: i, value: i + '"' });
                }
                // console.log("Min Dimention in For", ProductType.min_dimention, "Max Dimention in For", ProductType.max_dimention, "Incrment" ,i)
            }
            // console.log("Min Dimention out", ProductType.min_dimention, "Max Dimention out For", ProductType.max_dimention)
            console.log(width_array, 'width_array')
            return width_array;
        },

        closeShippingModal(id) {
            $(".modal-backdrop").remove();
            $("#" + id).removeClass("show");
            $("#" + id).attr("style", "");
            // $("#" + id).css("display", "none");
        },

        shippingCalculatorByZip(id) {
            // alert("Hello Main");
            console.log(id);
            // Show the backdrop
            $('<div class="modal-backdrop"></div>').appendTo(document.body);
            // Remove it (later)
            // $(".modal-backdrop").remove();
            $("#" + id).addClass("show");
            $("#" + id).css("display", "block");
        },

        getPage() {
            fetch(
                process.env.VUE_APP_BASE_URL + "page/" + "printing" + "?domain_id=2"
            )
                .then((response) => response.json())
                .then((json) => {
                    this.page_details = JSON.stringify(json.data.page_details, null, 2);
                    //   let body_text = JSON.parse(this.page_details);
                /// console.log(this.page_details,'this.page_details...meta_title??');

                     ////Set product meta tags //
                        // const descEl           = document.querySelector('head meta[name="keywords"]'); 
                        // const descTitle        = document.querySelector('head meta[name="title"]');
                        // const descdescription  = document.querySelector('head meta[name="description"]');
                        // ///console.log('descEl....',descEl); meta_description  description
                        // const titleEl = document.querySelector('head title'); 
                        // descEl.setAttribute('content',this.page_details.meta_keywords);
                        // descTitle.setAttribute('content',this.page_details.meta_title?this.page_details.meta_title:this.page_details.title);
                        // descdescription.setAttribute('content',this.page_details?.meta_description);
                        // titleEl.textContent this.page_details.title;   
                        ///// End ///  

                    this.page = ""; // body_text;

                    let myAttr = [];

                    let AttrHere = [];
                    let AllAtt = "";
                    myAttr.forEach(function (e) {
                        if (e[1] !== undefined) {
                            AllAtt = e[0].toLowerCase() + "-" + e[1].toLowerCase();
                        } else {
                            AllAtt = e[0].toLowerCase();
                        }
                        AttrHere.push(AllAtt);
                    });

                    this.getMyAttr = AttrHere;

                    if (this.page?.addons) {
                        this.banner = JSON.parse(this.page?.addons);
                        this.Base_Url =
                            this.$baseurlForImages + "page/thumb/" + this.banner.image;
                    }

                     
                });
        },

        uploadArtworkimg(elem) {
            console.log(elem, "elem");
            console.log("upload uploadArtworkimg");
            let formData = new FormData();

            let template_id = elem.target.getAttribute("data-templateid");
            let width = elem.target.getAttribute("data-width");
            let height = elem.target.getAttribute("data-height");
            let ratio = elem.target.getAttribute("data-ratio");
            //let weight = elem.target.getAttribute("data-weight");
            let files = elem.target;
            //let getname = files.getAttribute("name");

            formData.append("data[File][frontImage]", files.files[0]);
            formData.append("data[ratio]", ratio);
            formData.append("data[width]", width);
            formData.append("data[height]", height);
            formData.append("data[template_id]", template_id);
            $(".upload-progress").show("slow");
            this.uploadArtWork(formData)
                .then((res) => {
                    console.log(res, "res uploadArtWork");
                    if (res.data.result == "error") {
                        $(".upload-progress").hide("slow");
                        toast.error(res.data.data[0], { autoClose: 3200 });
                    } else {
                        console.log(res.data, "res.data.data");
                        $(".upload-progress").hide("slow");
                        this.productoptions = [];
                        this.productoptions = res.data.data;

                        let frameworkOption = res?.data?.data;
                        localStorage.removeItem("frameworkOption");
                        localStorage.setItem(
                            "frameworkOption",
                            JSON.stringify(frameworkOption)
                        );

                        /***check product option maps****/

                        let $_option_maping = [];
                        if (localStorage.getItem("product_option_map") !== null) {
                            // eslint-disable-next-line no-unused-vars
                            $_option_maping = JSON.parse(
                                localStorage.getItem("product_option_map")
                            );
                        }

                        // this.product_option_map = JSON.parse(this.getOptionStateMap);

                        let formData = new FormData();

                        let template_id = this.$store.state.product.ArtWork.id;
                        console.log( this.$store.state.product.ArtWork, "gggg")
                        let frontImg = this.$store.state.product.ArtWork.frontImage;
                        let backImg = this.$store.state.product.ArtWork.backImage;
                        let ratio = this.$store.state.product.ArtWork.data.ratio;
                        let template_config = JSON.parse(
                            localStorage.getItem("product_option_map")
                        );
                        formData.append("template_id", template_id);
                        formData.append("ratio", ratio);
                        formData.append("front_image", frontImg);
                        formData.append("back_image", backImg);
                        formData.append(
                            "template_config",
                            template_config.template_coating
                        );

                        /**********************Pre Declare Variables For Artwork *********************************/

                        // eslint-disable-next-line no-unused-vars
                        let left_ratio = Math.round(15000 / res.data.data.width);
                        // eslint-disable-next-line no-unused-vars
                        let left_width = (res.data.data.width * left_ratio) / 100;

                        let main_width = (res.data.data.width * ratio) / 100;
                        let main_height = (res.data.data.height * ratio) / 100;

                        // eslint-disable-next-line no-unused-vars
                        let larger_section =
                            main_width > main_height ? main_width : main_height;
                        // eslint-disable-next-line no-unused-vars
                        let left_height = (larger_section * left_ratio) / 100;
                        // eslint-disable-next-line no-unused-vars
                        console.log(
                            res.data.data.width,
                            "width",
                            res.data.data.height,
                            "height"
                        );

                        let artwork_margin_left = Math.round((850 - main_width) / 2);
                        let artwork_margin_top = Math.round(
                            (larger_section + 50 - main_height) / 2
                        );
                        console.log(
                            main_width,
                            main_height,
                            artwork_margin_left,
                            artwork_margin_top,
                            "hellomm"
                        );

                        // eslint-disable-next-line no-unused-vars
                        let rotate_margin_top = Math.round(
                            (larger_section + 50 - main_width) / 2
                        );
                        // eslint-disable-next-line no-unused-vars
                        let rotate_margin_left = Math.round((850 - main_height) / 2);

                        /**********************Pre Declare Variables For Artwork *********************************/

                        this.project.template_id = template_id;
                        // this.project.width = res.data.data.width;
                        // this.project.height = res.data.data.height;
                        this.project.ratio = ratio;
                        this.project.left_ratio = left_ratio;
                        this.project.left_width = left_width;

                        // this.project.main_width = main_width;
                        // this.project.main_height = main_height;
                        // this.project.artwork_margin_left = artwork_margin_left;
                        // this.project.artwork_margin_top = artwork_margin_top;

                        this.project.larger_section = larger_section;
                        this.project.left_height = left_height;
                        this.project.rotate_margin_top = rotate_margin_top;
                        this.project.rotate_margin_left = rotate_margin_left;
                        this.project.newwidth = Math.round(
                            (this.project.width * this.project.ratio) / 100
                        );
                        this.project.newheight = Math.round(
                            (this.project.height * this.project.ratio) / 100
                        );
                        this.project.actual_width = this.project.width;
                        this.project.actual_height = this.project.height;
                        this.project.org_width = this.project.width;
                        this.project.org_height = this.project.height;

                        if (res.data.data.rotate !== "undefined") {
                            this.project.rotate = res.data.data.rotate;
                        }

                        if (res.data.data.scale !== "undefined") {
                            this.project.scale = res.data.data.scale;
                        }

                        if (res.data.data.positionx !== "undefined") {
                            this.project.positionx = res.data.data.positionx;
                        }

                        if (res.data.data.positiony !== "undefined") {
                            this.project.positiony = res.data.data.positiony;
                        }

                        if (res.data.data.scaled_width !== "undefined") {
                            this.project.scaled_width = res.data.data.scaled_width;
                        }

                        if (res.data.data.scaled_height !== "undefined") {
                            this.project.scaled_height = res.data.data.scaled_height;
                        }

                        this.getFramework(formData)
                            .then((res) => {
                                console.log(res, "getFramework");
                                if (res.data.status == 0) {
                                    let msg = res.data.message;
                                    toast.error(msg, { autoClose: 2400 });
                                } else if (res.data.status == 1) {
                                    let getType = res.data.data;

                                    this.framework = getType;

                                    // eslint-disable-next-line no-unused-vars
                                    let getActive = $(".artwork-selection.selected").attr("rel");

                                    console.log(getActive, "getActive Top")
                                    $("#workspace .main-artwork#main-" + getActive).removeClass(
                                        "hidden"
                                    );

                                    if (getActive == "front") {
                                        // this.project.front = JSON.parse(
                                        //     localStorage.getItem("frameworkOption")
                                        // );
                                        this.project.front.file = JSON.parse(
                                            localStorage.getItem("frameworkOption")
                                        );
                                        this.project.front.file.positionx = 0;
                                        this.project.front.file.positiony = 0;
                                        this.project.front.file.scale = 100;
                                        this.project.front.file.rotate = 0;
                                    } else if (getActive == "back") {
                                        // this.project.back.file = res;
                                        this.project.back.file = JSON.parse(
                                            localStorage.getItem("frameworkOption")
                                        );
                                        this.project.back.file.positionx = 0;
                                        this.project.back.file.positiony = 0;
                                        this.project.back.file.scale = 100;
                                        this.project.back.file.rotate = 0;
                                    } else if (getActive == "backuv") {
                                        // this.project.backuv.file = res;
                                        this.project.backuv.file = JSON.parse(
                                            localStorage.getItem("frameworkOption")
                                        );
                                        this.project.backuv.file.positionx = 0;
                                        this.project.backuv.file.positiony = 0;
                                        this.project.backuv.file.scale = 100;
                                        this.project.backuv.file.rotate = 0;
                                    } else if (getActive == "frontuv") {
                                        // this.project.frontuv.file = res;
                                        this.project.frontuv.file = JSON.parse(
                                            localStorage.getItem("frameworkOption")
                                        );
                                        this.project.frontuv.file.positionx = 0;
                                        this.project.frontuv.file.positiony = 0;
                                        this.project.frontuv.file.scale = 100;
                                        this.project.frontuv.file.rotate = 0;
                                    } else if (getActive == "mattedull") {
                                        // this.project.mattedull.file = res;
                                        this.project.mattedull.file = JSON.parse(
                                            localStorage.getItem("frameworkOption")
                                        );
                                        this.project.mattedull.file.positionx = 0;
                                        this.project.mattedull.file.positiony = 0;
                                        this.project.mattedull.file.scale = 100;
                                        this.project.mattedull.file.rotate = 0;
                                    } else if (getActive == "frontmask") {
                                        // this.project.frontmask.file = res;
                                        this.project.frontmask.file = JSON.parse(
                                            localStorage.getItem("frameworkOption")
                                        );
                                        this.project.frontmask.file.positionx = 0;
                                        this.project.frontmask.file.positiony = 0;
                                        this.project.frontmask.file.scale = 100;
                                        this.project.frontmask.file.rotate = 0;
                                    } else if (getActive == "backmask") {
                                        // this.project.backmask.file = res;
                                        this.project.backmask.file = JSON.parse(
                                            localStorage.getItem("frameworkOption")
                                        );
                                        this.project.backmask.file.positionx = 0;
                                        this.project.backmask.file.positiony = 0;
                                        this.project.backmask.file.scale = 100;
                                        this.project.backmask.file.rotate = 0;
                                    }

                                    if (frameworkOption.is_special == true) {
                                        $("#workspace #main-" + getActive + " .user-artwork").html(
                                            '<img style="width:100%; max-width:100%;height:100%;" src="' +
                                            this.productoptions.path +
                                            '">'
                                        );
                                        $(".artwork-selection.selected").after(
                                            '<div class="upld-artwork-small"><img src="' +
                                            this.productoptions.path +
                                            '"></div>'
                                        );
                                    } else {
                                        this.frontframework = this.productoptions.path;
                                        $("#workspace #main-" + getActive + " .user-artwork").html(
                                            '<img style="width:100%; max-width:100%;height:100%;" src="' +
                                            this.productoptions.path +
                                            '">'
                                        );
                                        $(".artwork-selection.selected").after(
                                            '<div class="upld-artwork-small mt-4"><img style="width: 190px;height: 130px;" src="' +
                                            this.productoptions.path +
                                            '"></div>'
                                        );
                                    }

                                    var pos = "";
                                    let marginx = 0;
                                    let marginy = 0;
                                    
                                    if (eval("this.project." + getActive + ".rotate") > 0) {
                                        pos = $("#main-" + getActive + "-template").position();
                                        console.log(pos, "pos");
                                        console.log("#main-" + getActive + "-template");
                                        marginx = pos.left + (this.project.newheight - res.scaled_width) / 2;
                                        marginy = pos.top + (this.project.newwidth - res.scaled_height) / 2;
                                    } else {
                                        console.log(this.project, "project");
                                        console.log(this.project.newwidth, "this.project.newwidth");
                                        console.log(this.project.scaled_width, "this.project.scaled_width");
                                        console.log(this.project.newheight, "this.project.newheight");
                                        console.log(this.project.scaled_height, "this.project.scaled_height");
                                        // marginx =  (this.project.newwidth - this.project.scaled_width) / 2;
                                        // marginy =  (this.project.newheight - this.project.scaled_height) / 2;
                                        marginx = 0
                                        marginy = 0
                                        // marginx = (596.16 - this.project.scaled_width) / 2;
                                        // marginy = (452.16 - this.project.scaled_height) / 2;
                                        console.log("top", marginy, "left", marginx, "Hello Main");
                                    }
                                    console.log(getActive, "Hello Md Active")
                                    if (getActive == "front") {
                                        this.project.front.file.positionx = marginx;
                                        this.project.front.file.positiony = marginy;
                                    } else if (getActive == "back") {
                                        this.project.back.file.positionx = marginx;
                                        this.project.back.file.positiony = marginy;
                                    } else if (getActive == "backuv") {
                                        this.project.backuv.file.positionx = marginx;
                                        this.project.backuv.file.positiony = marginy;
                                    } else if (getActive == "frontuv") {
                                        this.project.frontuv.file.positionx = marginx;
                                        this.project.frontuv.file.positiony = marginy;
                                    } else if (getActive == "mattedull") {
                                        this.project.mattedull.file.positionx = marginx;
                                        this.project.mattedull.file.positiony = marginy;
                                    } else if (getActive == "frontmask") {
                                        this.project.frontmask.file.positionx = marginx;
                                        this.project.frontmask.file.positiony = marginy;
                                    } else if (getActive == "backmask") {
                                        this.project.backmask.file.positionx = marginx;
                                        this.project.backmask.file.positiony = marginy;
                                    }

                                    // let divwidth = (this.project.front.file.scaled_width * (this.project.front.file.scale / 100)).toFixed(3);
                                    // let divheight = (this.project.front.file.scaled_height * (this.project.front.file.scale / 100)).toFixed(3);
                                    console.log(this.project[getActive].file, 'getActiveddddd Md')

                                    let divwidth = (this.project[getActive].file.scaled_width * (this.project[getActive].file.scale / 100)).toFixed(3);

                                    let divheight = (this.project[getActive].file.scaled_height * (this.project[getActive].file.scale / 100)).toFixed(3);
                                    

                                    //  this.project.front.file.positiony = this.project.front.file.positiony < 0 ? 0 : this.project.front.file.positiony;

                                    this.move.top = marginy;
                                    this.move.left = marginx;
                                    this.move.width = divwidth;
                                    this.move.height = divheight;
                                    Store_template(
                                        this.project,
                                        getActive,
                                        width,
                                        height,
                                        ratio,
                                        this.move
                                    );
                                    $("#artworkimgside").show();
                                    $(".upld-artwork-small.mt-4").show();
                                    toast.success("image uploaded successfully", {
                                        autoClose: 2400,
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });

                        setTimeout(() => {
                            $(".artwork-success").hide("slow");
                        }, 900);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        get_shipping_quote() {
            this.userZip = $("#UserZip").val();

            console.log("this.userZip....", this.userZip);

            if (this.userZip.length == 0) {
                console.log("if....");
                this.userZip = "";
                $("#get_shipping_quote").html("Get Shipping Estimation:");
                $("#ship-modal-link").html(
                    '<img alt="" style="width:24px" src="/img/ups-logo.png">'
                );
            } else {
                console.log("else....");

                $("#ship-modal-content").html(
                    "Shipping Estimation will be calculated to <strong> " +
                    this.userZip +
                    "</strong>"
                );
                $("#ship-modal-link").html("change");
                this.shipping_details = 1;
                this.prepareRequest($("#ship-modal-content"));
                this.closeShippingModal("shipping-calculator");
            }
        },

        resetArtworkWorkspace() {
            let confirmyes = confirm("Are you really want to reset the artwork?");
            //   let getimg = document.getElementById("image-uploader");
            //   let getArtworkimg = document.getElementById("artworkimg");
            //   let getsideartworkimg = document.getElementById("artworkimgside");
            if (confirmyes == true) {
                $("#main-back .user-artwork").html("");
                $("#main-front .user-artwork").html("");
                $("#main-backuv .user-artwork").html("");
                $("#main-frontuv .user-artwork").html("");
                $("#main-mattedull .user-artwork").html("");
                $("#main-frontmask .user-artwork").html("");
                $("#main-backmask .user-artwork").html("");
                $(".art-lft .upld-artwork-small").remove();
                // if (getArtworkimg !== null && getsideartworkimg !== null) {
                //   getArtworkimg.setAttribute("src", "");
                //   getsideartworkimg.setAttribute("src", "");
                //   $(".upld-artwork-small.mt-4").hide();
                //   getimg.value = "";
                //   toast.success("Reset successfully", { autoClose: 1400 });
                // }
                // if (getsideartworkimg !== null) {
                //   getsideartworkimg.setAttribute("src", "");
                // }
            }
        },

        /****operations on artwork ****/
        scaleUp(position) {
            let template_coating = position.template_coating;

            template_coating.map((e) => {
                if (e == "front") {
                    console.log(this.project.front.file.scale, "scaleUp");
                }
            });
        },
        /****operations on artwork *****/

        getProductOptionValue(e) {
            this.data.data.options.push(
                e.target.options[e.target.options.selectedIndex].dataset
            );
            let getElem = e.target;
            let templateAttr = getElem.getAttribute("data-change-template");
            if (parseInt(templateAttr) == 1) {
                $("#total-templates").attr("data-changed", 1);
            }

            this.product_id = e.target.getAttribute("data-product-id");
            this.selectedVal =
                e.target.options[e.target.options.selectedIndex].dataset.val;

            let _attr_color = $(".attr-colors option:selected").text().toLowerCase();
            let _attr_finishes = $(".attr-finishes option:selected")
                .text()
                .toLowerCase();
            let _finish_text = "Spot UV Both Sides".toLowerCase();
            let _attr_turnaround = $(".attr-turnaround option:selected")
                .text()
                .toLowerCase();
            console.log(
                _attr_color,
                "_attr_color",
                _attr_finishes,
                "_attr_finishes",
                _finish_text,
                "_attr_turnaround",
                _attr_turnaround
            );

            if (
                _attr_color.indexOf("4/0") != -1 &&
                _attr_finishes.indexOf(_finish_text) != -1
            ) {
                alert("You can not select a second side finish with a one sided color");
                $(".attr-finishes").val("0");
                console.log("Hello world");
                return false;
            }

            if (
                _attr_finishes.indexOf("spot uv") != -1 &&
                _attr_turnaround.indexOf("next day") != -1
            ) {
                alert(
                    "You can not select 'next day' turn around time for SPOT UV finishes."
                );
                $(".attr-turnaround").val("0");
                return;
            }

            this.prepareRequest($(this));
            //  this.prepareRequest($(".trigger-change:first"));
        },

        prepareRequest() {
            let dataToSend = {};
            let dataToCheckout = {};
            dataToSend.data = {};
            dataToCheckout.data = {};

            let aa = $.find(".trigger-change");
            console.log(aa, 'aa')

            let pid = $('input[name="data[Checkout][product_id]"]')[0];
            console.log(pid?.value, 'pid.value')
            this.product_id = pid.value;

            let inputFIle = $.find("fileupload");

            // let uploadedFileName=$.find('.uploadfileName');
            //let uploadedFIlePath=$.find('.uploadfilePath');

            let sendArr = [];

            $.each(aa, function (ind, vals) {
                var po = {};
                // console.log('vals.....??',vals);
                var poId = $(vals).attr("rel").split("-");
                po.id = poId[1];

                po.val =
                    vals[vals.options.selectedIndex].dataset.val === undefined
                        ? 0
                        : vals[vals.options.selectedIndex].dataset.val; //$(vals).vals();

                /***get selected text *****/
                po.text =
                    vals.options[vals.options.selectedIndex].text === undefined
                        ? 0
                        : vals.options[vals.options.selectedIndex].text;
                /***get selected text *****/

                if (po.text == "Please Select") {
                    po.text = "";
                }

                //console.log(po)
                if (po.id == "qty:drpdn") {
                    dataToSend.data.qty = $("#CheckoutQuantity").val(); //po.val;
                } else if (po.id == "width:drpdn") {
                    dataToSend.data.width = $("#CheckoutWidth").val(); // po.val;
                } else if (po.id == "height:drpdn") {
                    dataToSend.data.height = $("#CheckoutHeight").val(); //po.val;
                } else {
                    sendArr.push(po);
                }
            });

            $.each(inputFIle, function (i, e) {
                let getfile = e.value;
                sendArr.push(getfile);
            });

            /*****Get  option id & value *****/
            let newsendArr = [];
            $.each(sendArr, function (i, e) {
                let get_id = e.id;
                let get_val = e.val;

                //get selected text
                let get_text = e.text;
                //get selected text

                if (get_id.indexOf(":") > -1) {
                    let gid = get_id.split(":");
                    let getidhere = gid[0];

                    let getelem = {
                        id: getidhere,
                        val: get_val,
                        text: get_text,
                    };

                    newsendArr.push(getelem);
                }
            });

            //console.log(newsendArr);

            /*****Get  option id & value *****/

            dataToSend.data.options = sendArr;

            dataToCheckout.data.Checkout = {};
            dataToCheckout.data.Checkout.option = newsendArr;
            dataToCheckout.data.Checkout.product_id = this.product_id;

            dataToSend.data.Product = {};

            dataToSend.data.Product.id = this.product_id;

            //console.log(thisElement)

            dataToSend.data.Product.selected = this.selectedVal;

            dataToSend.data.Shipping = {};

            dataToSend.data.Shipping.userZip = this.userZip;
            dataToSend.data.Shipping.shipping_details = this.shipping_details;

            /****get Attribute **********/

            this.setAttribute(dataToSend)
                .then((res) => {
                    console.log("res....hasFile???---999-", res);

                    if (res.status == 200) {
                        let result = res.data;
                        this.shipObj = result;
                        let _that = this;
                        let price = parseFloat(result.data.price).toFixed(2);

                        $("#product-price1").val(price);

                        let encodedData = result.encodedData;
                        if (localStorage.getItem("product_option_map") === null) {
                            localStorage.setItem("product_option_map", encodedData);
                        } else {
                            localStorage.setItem("product_option_map", encodedData);
                        }
                        //set shipping price _that.shipObj.priceObj //

                        // if (localStorage.getItem("priceObj") === null) {
                        //   localStorage.setItem(
                        //     "priceObj",
                        //     JSON.stringify(this.shipObj.data.shipReturn)
                        //   );
                        //   _that.priceObj = this.shipObj.data.shipReturn;
                        // } else {
                        //   if (this.shipObj.data?.shipReturn.length > 0) {
                        //     localStorage.setItem(
                        //       "priceObj",
                        //       JSON.stringify(this.shipObj.data.shipReturn)
                        //     );
                        //   } else {
                        //     _that.priceObj = this.shipObj.data.shipReturn?.priceObj;
                        //   }
                        // }

                        _that.priceObj = this.shipObj.data.shipReturn;
                        let finaltotal = 0.0;
                        let subtotal = 0.0;
                        let shipping_cost = 0.0;
                        // subtotal = price;

                        shipping_cost = localStorage.getItem("element_shipping_cost");
                        if (shipping_cost) {
                            finaltotal = parseFloat(price) + parseFloat(shipping_cost);
                        } else {
                            finaltotal = parseFloat(price);
                        }

                        console.log("finaltotal...-->>", finaltotal);

                        //end   + parseFloat(shipping_cost) //
                        $("#product-price").text("$" + finaltotal);
                        this.baseSubtotal = price;
                        subtotal = price;
                        dataToCheckout.data.Checkout.price = price;
                        dataToCheckout.data.Checkout.total_price = (price * 1).toFixed(2);
                        /****** Folding image show ******/
                        if (res.data.data.hasFile == 1) {
                            this.hasFile = res.data.data.hasFile;
                            this.file = res.data.data.file;
                            //`${process.env.VUE_APP_BASE_URL_WEB}files/`
                            let baseUrl = process.env.VUE_APP_BASE_URL_WEB;
                            this.productoptionimage =
                                '<a href="' +
                                baseUrl +
                                "files/" +
                                res.data.data.file.folder +
                                "/" +
                                res.data.data.file.name +
                                '" target="_blank"><img src="' +
                                baseUrl +
                                "files/" +
                                res.data.data.file.folder +
                                "/" +
                                res.data.data.file.name +
                                '" style="max-width: 140px;" /></a>';
                        }

                        $("#element_shipping_cost").change(function () {
                            if (
                                _that.priceObj.priceObj[$("#element_shipping_cost").val()] !=
                                undefined
                            ) {
                                console.log(
                                    "subtotal?????---",
                                    subtotal,
                                    "Hello",
                                    _that.priceObj.priceObj[$("#element_shipping_cost").val()]
                                );
                                finaltotal = 0.0;
                                finaltotal =
                                    parseFloat(subtotal) +
                                    parseFloat(
                                        _that.priceObj.priceObj[$("#element_shipping_cost").val()]
                                    );

                                if (
                                    localStorage.getItem("element_shipping_cost_key") === null
                                ) {
                                    localStorage.setItem(
                                        "element_shipping_cost_key",
                                        $("#element_shipping_cost").val()
                                    );
                                    localStorage.setItem(
                                        "element_shipping_cost",
                                        _that.priceObj.priceObj[$("#element_shipping_cost").val()]
                                    );
                                } else {
                                    if ($("#element_shipping_cost").val()) {
                                        localStorage.setItem(
                                            "element_shipping_cost_key",
                                            $("#element_shipping_cost").val()
                                        );
                                        localStorage.setItem(
                                            "element_shipping_cost",
                                            _that.priceObj.priceObj[$("#element_shipping_cost").val()]
                                        );
                                    }
                                }
                                console.log("product-price//////???", finaltotal.toFixed(2));
                                $("#product-price").html(
                                    "$ " + finaltotal.toFixed(2) + "</span>"
                                );
                            } else {
                                finaltotal = parseFloat(subtotal) + 0;
                                $("#product-price").html("$ " + finaltotal + "</span>");
                            }
                        });

                        if (res.data.data.shipChanged == 1) {
                            //  shipping-total
                            var shipping_html = '<option value="0">Select Method</option>';
                            this.shipObj = res.data.data.shipReturn;
                            var ship_arr = new Array();

                            for (var key in this.shipObj.shipping) {
                                this.shipObj.shipping[key].code = key;
                                ship_arr.push([
                                    parseFloat(this.shipObj.shipping[key].price),
                                    this.shipObj.shipping[key],
                                ]);
                            }
                            ship_arr.sort(function (a, b) {
                                return a[0] - b[0];
                            });
                            $.each(ship_arr, function (ind, shipOb) {
                                shipping_html +=
                                    '<option value="' +
                                    shipOb[1].code +
                                    '">' +
                                    shipOb[1].description +
                                    " ($" +
                                    Number(shipOb[1].price).toFixed(2) +
                                    ")</option>";
                            });

                            $("#element_shipping_cost").html(shipping_html);
                            $("#element_shipping_cost").removeClass("fade");
                            this.shipping_details = 0;
                        }
                        /************* End ********/
                        console.log("finaltotal...--End->", finaltotal);
                    }
                })
                .catch((error) => {
                    console.log(error, "error");
                });
            /****get Attribute **********/

            /****Prepare for checkout*****/
            // console.log(dataToCheckout);
            this.AllDataSend = dataToCheckout;
        },

        uploadFile(event) {
            event.preventDefault();

            let uploader = document.getElementById("classic-uploader-div");

            if (uploader.classList.contains("hidden") == true) {
                uploader.classList.remove("hidden");
            } else {
                uploader.classList.add("hidden");
            }
        },

        upload_File(e) {
            $("#allfiles").empty();
            const fileList = e.target.files;
            let fileNames = [];
            // let filePath=[]
            let getFilePath = [];
            let filePreview = this.$refs.file_preview; //document.getElementById('filepreview');

            $.each(fileList, function (i, e) {
                let fname = e.name;
                fileNames.push(fname);
                const getfileType = fname.split(".");
                const filename = getfileType[0];
                const fileType = getfileType[1];
                getFilePath.push(fileType);
                let reader = new FileReader();
                let inputfilename = document.createElement("input");
                let inputfilepath = document.createElement("input");

                inputfilename.setAttribute("type", "hidden");
                inputfilepath.setAttribute("type", "hidden");

                inputfilename.setAttribute("id", `filename_${i}`);
                inputfilepath.setAttribute("id", `filepath_${i}`);

                inputfilename.setAttribute("class", "uploadfileName");
                inputfilepath.setAttribute("class", "uploadfilePath");

                inputfilename.setAttribute("name", `getfilename_[${i}]`);
                inputfilepath.setAttribute("name", `getfilepath_[${i}]`);

                inputfilename.setAttribute("value", fname);

                reader.readAsDataURL(e);

                // $(filePreview).empty();
                if (  fname.indexOf("j") > -1 ||  fname.indexOf("png") > -1 || fname.indexOf("gif") > -1 || fname.indexOf("tif") > -1  ) {
                    (reader.onload = function () {
                        //filePath.push(reader.result)

                        $(filePreview).append(
                            `<div class="mt-3 col-md-4" id="${filename}">
                                <span>
                                    <img class="img-fluid" src='${reader.result}' />
                                </span>
                            </div>`
                        );
                        inputfilepath.setAttribute("value", reader.result);

                        if (document.getElementById(`removeimg_${filename}`)) {
                            document.getElementById(`removeimg_${filename}`)

                            .addEventListener("click", function (el) {
                                el.preventDefault();
                                let confirm_first = confirm(
                                    "Are you sure you want to delete image?"
                                );
                                if (confirm_first == true) {
                                    document.getElementById(`removeimg_${filename}`).remove();
                                    toast.success("Image removed successfully", {
                                        autoClose: 600,
                                    });

                                    if (filePreview[0].childElementCount <= 0) {
                                        document.getElementById("fileUploaded").value = "";
                                    }
                                } else {
                                    return false;
                                }
                            });
                        }

                    }),

                    (reader.onerror = function () {
                        console.log(reader.error);
                    });
                }

                if (fname.indexOf("doc") > -1) {
                    (reader.onload = function () {
                        const filedocimg = "img/files/docx.png";

                        $(filePreview).append(
                            `<div class="mt-3 col-md-4 ${filename}">
                                <span>
                                    <img class="img-fluid" src='${filedocimg}' />
                                </span>
                            </div>`
                        );

                        inputfilepath.setAttribute("value", reader.result);
                        if (document.getElementById(`removeimg_${filename}`)) {
                            document
                                .getElementById(`removeimg_${filename}`)
                                .addEventListener("click", function (el) {
                                    el.preventDefault();
                                    let confirm_first = confirm(
                                        "Are you sure you want to delete image?"
                                    );
                                    if (confirm_first == true) {
                                        document.getElementById(`removeimg_${filename}`).remove();
                                        toast.success("Image removed successfully", {
                                            autoClose: 600,
                                        });

                                        if (filePreview[0].childElementCount <= 0) {
                                            document.getElementById("fileUploaded").value = "";
                                        }
                                    } else {
                                        return false;
                                    }
                                });
                        }
                    }),
                    (reader.onerror = function () {
                        console.log(reader.error);
                    });
                }

                if (fname.indexOf("pdf") > -1) {
                    (reader.onload = function () {
                        const filepdfimg = "img/files/pdf.jpg";
                        $(filePreview).append(
                            `<div class="mt-3 col-md-4 ${filename}">
                                <span>
                                    <img class="img-fluid" src='${filepdfimg}' />
                                </span>
                            </div>`
                        );
                        inputfilepath.setAttribute("value", reader.result);
                        if (document.getElementById(`removeimg_${filename}`)) {
                            document
                                .getElementById(`removeimg_${filename}`)
                                .addEventListener("click", function (el) {
                                    el.preventDefault();
                                    let confirm_first = confirm(
                                        "Are you sure you want to delete image?"
                                    );
                                    if (confirm_first == true) {
                                        document.getElementById(`removeimg_${filename}`).remove();
                                        toast.success("File removed successfully", {
                                            autoClose: 600,
                                        });

                                        if (filePreview[0].childElementCount <= 0) {
                                            document.getElementById("fileUploaded").value = "";
                                        }
                                    } else {
                                        return false;
                                    }
                                });
                        }
                    }),
                    (reader.onerror = function () {
                        console.log(reader.error);
                    });
                }

                if (fname.indexOf("xl") > -1) {
                    (reader.onload = function () {
                        const filexlsmg = "img/files/xlsx.png";
                        $(filePreview).append(
                            `<div class="mt-3 col-md-4 ${filename}">
                                <span>
                                    <img class="img-fluid" src='${filexlsmg}' />
                                </span>
                            </div>`
                        );
                        inputfilepath.setAttribute("value", reader.result);
                        if (document.getElementById(`removeimg_${filename}`)) {
                            document.getElementById(`removeimg_${filename}`)
                                .addEventListener("click", function (el) {
                                    el.preventDefault();

                                    let confirm_first = confirm(
                                        "Are you sure you want to delete image?"
                                    );
                                    if (confirm_first == true) {
                                        document.getElementById(`removeimg_${filename}`).remove();
                                        toast.success("File removed successfully", {
                                            autoClose: 600,
                                        });

                                        if (filePreview[0].childElementCount <= 0) {
                                            document.getElementById("fileUploaded").value = "";
                                        }
                                    } else {
                                        return false;
                                    }
                                });
                        }
                    }),
                        (reader.onerror = function () {
                            console.log(reader.error);
                        });
                }

                //File rendering starts//
                $("#allfiles").append(inputfilename);
                $("#allfiles").append(inputfilepath);
                //FIle rendering starts//
            });
        },

        formSubmit() {
            let Pro_detail = this.$refs.productid;
            let ret = true;
            let formData = new FormData();
            let SelectData = this.$refs.getFields;
            let inputData = this.$refs.filename;
            console.log(inputData, "Hello File New")

            let Artworkfiles = localStorage.getItem("artworkFiles") !== null ? JSON.parse(localStorage.getItem("artworkFiles")) : [];
            //let fileData= this.$refs.myfiles;
            let fileData = document.getElementById("fileUploaded") != null ? document.getElementById("fileUploaded").files : "";

            // let Pro_price = this.$refs.product_price;
            // let get_price = Pro_price.innerText.split("$");
            let price = $("#product-price1").val(); //parseFloat(get_price[1]).toFixed(2);

            let allowed_ext = ["jpg", "tiff", "tif", "eps", "pdf", "png", "jpeg"];

            if (fileData.length <= 0) {
                //  document.getElementById('classic-uploader-div').classList.remove('hidden')
                //  document.getElementById('fileUploaded').setAttribute('required','required');
                //  ret=false;
            } else {
                $.each(fileData, (i, e) => {
                    let getname = e.name;
                    let getx = getname.split(".");
                    let ext = getx[1];

                    if (allowed_ext.includes(ext) !== true) {
                        document.getElementById("fileUploaded").nextElementSibling.innerHTML = `<p class="mt-2">Allowed Extension are ${allowed_ext.join(",")}`;
                        document.getElementById("classic-uploader-div").classList.remove("hidden");
                        ret = false;
                    } else {
                        document.getElementById("fileUploaded").nextElementSibling.innerHTML = "";
                        document.getElementById("classic-uploader-div")
                            .classList.add("hidden");
                        ret = true;
                    }
                });
                document.getElementById("fileUploaded").removeAttribute("required");
                document.getElementById("classic-uploader-div").classList.add("hidden");
            }

            let Pro_id = "";
            let Pro_Name = "";
            var optionCount = 1;
            if (Pro_detail.length > 0) {
                $.each(Pro_detail, function (i, e) {
                    // console.log(e, 'value', e.value, 'name', e.getAttribute("name"), "Hello Main from each")
                    Pro_id = e.value;
                    Pro_Name = e.getAttribute("name");
                });
            } else {
                Pro_id = Pro_detail.value;
                Pro_Name = Pro_detail.getAttribute("name");
                // console.log(Pro_detail.getAttribute("name"), Pro_detail.value, 'Pro_detail length esle')
                // return
            }

            console.log("SelectData???......", SelectData);

            $.each(SelectData, function (i, e) {
                //  ++i;
                /***get label name ******/
                console.log("i......", i, e);
                let Label = "";
                if (e.parentElement.parentElement.hasChildNodes) {
                    let getLabel = e.parentElement.parentElement.children[0].children[0];
                    Label = getLabel?.innerText;
                }

                /***get label name ******/
                let getName = e.getAttribute("name");
                let getDataid = e.getAttribute("data-id");
                let getid = e.getAttribute("data-index");
                let getids = e.getAttribute("id");
                console.log("getid...", getids);
                let index1 = getid.split("-")[1];
                // console.log("getName..", getName);
                // console.log("getDataid..", getDataid);

                let getId = e.options[e.selectedIndex].getAttribute("data-id");
                let getDid = getId.split(":");
                let getvalueid = getDid[0];
                let getValue = e.options[e.selectedIndex].getAttribute("data-val");
                let getText = e.options[e.selectedIndex].text;
                console.log("e.selectedIndex..", e.selectedIndex);
                console.log("getvalueid..", getvalueid);
                console.log("getDataid..", getDataid);
                // if (
                //   getids != "CheckoutWidth" ||
                //   getids != "CheckoutHeight" ||
                //   getids != "CheckoutQuantity"
                // ) {
                formData.append(getDataid, getvalueid); //append select option id
                formData.append(`data[Checkout][option][${index1}][label]`, Label);
                formData.append(`data[Checkout][option][${index1}][text]`, getText);
                formData.append(getName, getValue); //append select option value
                optionCount++;
                //}
            });
            //check large format product  add width, height & Qty

            if (this.$store.state.product.SingleProduct?.data?.product_type?.large_format && this.$store.state.product.SingleProduct?.data?.product_type?.large_format == 1) {
                //large-product
                const selectedArr = document.querySelectorAll(".large-product");
                $.each(selectedArr, function (i, e) {
                    /***get label name ******/
                    console.log("i.....", i);
                    console.log("i......large-product??/?");
                    let Label = "";
                    let index1 = "";
                    // if (e.parentElement.parentElement.hasChildNodes) {
                    //   let getLabel =
                    //     e.parentElement.parentElement.children[0].children[0];
                    //   Label = getLabel?.innerText;
                    // }

                    /***get label name ******/
                    let getName = e.getAttribute("name");
                    let getDataid = e.getAttribute("data-id");
                    let getid = e.getAttribute("data-index");
                    /// let getids = e.getAttribute("id");  data-label

                    Label = e.getAttribute("data-label");

                    index1 = getid.split("-")[1];

                    // console.log("getName..", getName);
                    // console.log("getDataid..", getDataid);
                    console.log(e, "Event Data MD")
                    let getId = e.options[e.selectedIndex].getAttribute("data-id");
                    console.log(getId, "Hello Main Md")
                    let getDid = getId.split(":");

                    let getvalueid = getDid[0];

                    let getValue = e.options[e.selectedIndex].getAttribute("value");

                    let getText = e.options[e.selectedIndex].text;

                    console.log("Label..", Label);
                    console.log("getvalueid..", getvalueid);
                    console.log("getDataid..", getDataid);
                    console.log("getText..", getText);
                    console.log("getValue..", getValue);
                    // if (
                    //   getids != "CheckoutWidth" ||
                    //   getids != "CheckoutHeight" ||
                    //   getids != "CheckoutQuantity"
                    // ) {
                    formData.append(getDataid, e.getAttribute("data-idval")); //append select option id
                    formData.append(`data[Checkout][option][${index1}][label]`, Label);
                    formData.append(`data[Checkout][option][${index1}][text]`, getText);
                    formData.append(getName, getValue); //append select option value
                    optionCount++;
                    //}
                });

                //width
                // let CheckoutWidthgetName   = $("#CheckoutWidth").getAttribute("name");
                // let CheckoutWidthgetDataid = $("#CheckoutWidth").getAttribute("data-id");
                // let CheckoutWidthgetid     = $("#CheckoutWidth").getAttribute("data-index");
                // let CheckoutWidthgetids    = $("#CheckoutWidth").getAttribute("id");

                // formData.append("data[Checkout][width]", $("#CheckoutWidth").val()); //append Product  width

                // //end //
                // formData.append("data[Checkout][height]", $("#CheckoutWidth").val()); //append Product  height
                // formData.append("data[Checkout][qty]", $("#CheckoutQuantity").val()); //append Product  width
            }

            // end ///

            let atrtWork_file_data = "";
            atrtWork_file_data = $("#template-for-product").val();
            console.log("atrtWork_file_data...??? Hello Main MD", atrtWork_file_data);
            if (atrtWork_file_data) {
                //rel-key template-for-product-id
                let rel_key = $("#template-for-product").attr("rel-key");
                let rel_val_id = $("#template-for-product-id").val();
                formData.append(`data[Checkout][option][${rel_key}][id]`, rel_val_id);
                formData.append(`data[Checkout][option][${rel_key}][value]`, atrtWork_file_data);
            } else {
                let rel_key = "";
                rel_key = $("#template-for-product").attr("rel-key");
                ///  alert("rel_key..", rel_key);
                let rel_val_id = $("#template-for-product-id").val();
                if (rel_key) {
                    formData.append(`data[Checkout][option][${rel_key}][id]`, rel_val_id);
                    formData.append(`data[Checkout][option][${rel_key}][value]`, atrtWork_file_data);
                }
            }

            formData.append(Pro_Name, Pro_id); //Append Product Id
            let getInputName = inputData.getAttribute("name");
            let getInputValue = inputData.value;
            formData.append(getInputName, getInputValue); //Append Input file name
            formData.append(Pro_Name, Pro_id); //Append Input file value
            formData.append("data[Checkout][price]", price); //append Product Price

            //let filehere=[]

            for (let i = 0; i < fileData.length; i++) {
                formData.append("data[File][image][" + i + "]", fileData[i]);
                formData.append("data[File][filenames_arr][" + i + "]", fileData[i]);
                optionCount++;
            }
            console.log("optionCount..", optionCount);

            if (fileData.length <= 0) {
                formData.append("data[File][filenames_arr]", []);
            }
            if (Artworkfiles) {
                formData.append("data[Artwork][image][name]", Artworkfiles.file_name === undefined ? "" : Artworkfiles.file_name);
                formData.append("data[Artwork][image][path]", Artworkfiles.file_path === undefined ? "" : Artworkfiles.file_path);
            }

            if (localStorage.getItem("current_order_id") !== "") {
                let getcurrentorder = localStorage.getItem("current_order_id");
                if (getcurrentorder) {
                    formData.append("current_order_id", getcurrentorder);
                }
            }

            if (inputData.value.length > 0) {
                // optionCount++;
                // formData.append(
                //   "data[Checkout][filename]",
                //   "user_filename"
                // );
                /// formData.append("data[Checkout][filename]", inputData.value);
                //inputData.value
            }

            //optionCount++;
            //formData.append(
            //"data[Checkout][option][" + optionCount + "][id]",
            //"filenames_arr"
            //);
            //formData.append("data[Checkout][option][" + optionCount + "][value]", []);

            //let allfiles=[]

            // let getn=document.getElementById('fileUploaded').getAttribute('name')
            // formData.append('data[File][image]',fileData)

            //append file data

            // console.log("formData..", formData);
            // console.log("Hello min ffff")
            // console.log(ret);
            // return

            if (ret == false) {
                return false;
            } else if (ret == true) {
                this.getCartData(formData)
                    .then((res) => {
                        if (res.status == 200) {
                            console.log(res, res?.data?.data?.order_id, "order_id ddd");
                            toast.success("Item added succesfully", { autoClose: 1200 });
                            if (localStorage.getItem("current_order_id") === null) {
                                let order_id = res?.data?.data?.order_id;
                                localStorage.setItem("current_order_id", order_id);
                            }
                            this.$router.push("/cart");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            //console.log(formData)

            //this.getCartData(formData)
        },

        openCanvas() {
            console.log("formData");
            $("#artwork-upld-link").attr("data-bs-toggle", "modal");
            $("#artwork-upld-link").attr("data-bs-target", "#canvaspopup");
            let getoptions =  localStorage.getItem("product_option_map") !== null  ? JSON.parse(localStorage.getItem("product_option_map"))  : {}; let artworkoptions = this.prodata;
            // console.log(getoptions.template_coating, "getoptions")
            //this.getoptionmap.push(getoptions.template_coating);
            //console.log(this.getoptionmap);

            this.template_id = this.getProductTemplate;
            console.log("this.template_id ..", this.template_id);

            this.getArtWork(this.template_id)
                .then((res) => {
                    if (res.status == 1 && res.success == true) {
                        console.log(res.data, "res.data");
                        let getdata = res.data;
                        let artworkname = getdata.name;
                        let templateid = getdata.id;
                        let height = parseInt(getdata.height);
                        let width = parseInt(getdata.width);
                        let weight = parseInt(getdata.weight);
                        let frontImage = getdata.frontImage;
                        let backImage = getdata.backImage;
                        let instruction = getdata.instructions;
                        let ratio = getdata.data.ratio;
                        this.artWorkData.template_id = templateid;
                        this.artWorkData.name = artworkname;
                        this.artWorkData.width = width;
                        this.artWorkData.height = height;
                        this.artWorkData.weight = weight;
                        this.artWorkData.front_image = frontImage;
                        this.artWorkData.back_image = backImage;
                        this.artWorkData.instruction = instruction;
                        this.artWorkData.ratio = ratio;

                        if (
                            Object.prototype.hasOwnProperty.call(artworkoptions, "ArtWork") &&
                            Object.prototype.hasOwnProperty.call(artworkoptions, "ArtWork") ==
                            true
                        ) {
                            this.getoptionmap.Artwork = artworkoptions.ArtWork;
                            let siteurl = process.env.VUE_APP_BASE_URL_WEB;
                            let artworkid =
                                typeof artworkoptions.ArtWork.id !== "undefined"
                                    ? artworkoptions.ArtWork.id
                                    : 1;
                            let getratio = Object.prototype.hasOwnProperty.call(
                                artworkoptions.ArtWork.data,
                                "ratio"
                            )
                                ? artworkoptions.ArtWork.data.ratio
                                : 0;

                            let getfrontimg =
                                typeof artworkoptions.ArtWork.frontImage !== "undefined"
                                    ? artworkoptions.ArtWork.frontImage
                                    : "";
                            let getbackimg =
                                typeof artworkoptions.ArtWork.backImage !== "undefined"
                                    ? artworkoptions.ArtWork.backImage
                                    : "";
                            let frontImg = {
                                front:
                                    siteurl +
                                    "files/" +
                                    "templates/" +
                                    artworkid +
                                    "/" +
                                    Math.round(getratio) +
                                    "/" +
                                    getfrontimg,
                                back:
                                    siteurl +
                                    "files/" +
                                    "templates/" +
                                    artworkid +
                                    "/" +
                                    Math.round(getratio) +
                                    "/" +getbackimg,
                            };
                            this.getoptionmap.path = frontImg;
                            
                            console.log(this.getoptionmap.path, "nadeem");
                        }
                        this.getoptionmap.template_config = getoptions.template_coating;

                        // Create height, width, top, left for artwork frame start
                        let main_width = (width * ratio) / 100;
                        let main_height = (height * ratio) / 100;
                        // eslint-disable-next-line no-unused-vars
                        let larger_section =
                            main_width > main_height ? main_width : main_height;
                        let artwork_margin_left = Math.round((850 - main_width) / 2);
                        // eslint-disable-next-line no-unused-vars
                        let artwork_margin_top = Math.round(
                            (larger_section + 50 - main_height) / 2
                        );
                        console.log(
                            main_width,
                            main_height,
                            artwork_margin_left,
                            artwork_margin_top
                        );
                        this.project.artwork_margin_top = artwork_margin_top;
                        this.project.artwork_margin_left = artwork_margin_left;
                        this.project.main_width = main_width;
                        this.project.main_height = main_height;

                        this.project.width = width;
                        this.project.height = height;

                        let mainartstyle = {
                            "margin-top": artwork_margin_top + "px",
                            "margin-left": artwork_margin_left + "px",
                            width: main_width + "px",
                            height: main_height + "px",
                            position: "relative",
                        };

                        setTimeout(() => {
                            let getActive = $(".artwork-selection.selected").attr("rel");
                            const artwork_selection_list = document.getElementsByClassName('artwork-selection');
                            $(artwork_selection_list).each(function( index, element ) {
                                if (element.classList.contains('selected')) {
                                    $(element).removeClass('selected')
                                }
                                let typeOfArtowrk = $(element).attr("rel");
                                if (getoptions?.template_coating[0] == typeOfArtowrk) {
                                    $(element).addClass('selected')
                                }
                            });

                            // $('.artwork-selection').on('click', function () {
                            //     $('.artwork-selection').removeClass('selected');
                            //     $(this).addClass('selected');
                            //     const active = $(this).attr('rel');
                            //     $('.main-artwork').addClass('hidden');
                            //     $('#main-' + active).removeClass('hidden');
                            // });
                             
                            console.log(getActive, "getActive");
                            // console.log(getoptions?.template_coating[0], "MD")
                            // $("#workspace .main-artwork#main-" + getActive).removeClass(
                            $("#workspace .main-artwork#main-" + getoptions?.template_coating[0]).removeClass(
                                "hidden"
                            );
                            $(".main-artwork").css(mainartstyle);

                        }, 500);
                        // Create height, width, top, left for artwork frame End

                        console.log(res, "getArtWork res");

                        $("#loader").hide("slow");
                    }
                })
                .catch((error) => {
                    console.log(error, "Error MD loading");
                });
        },

        dataURItoBlob(dataURI, type) {
            // convert base64 to raw binary data held in a string
            var byteString = atob(dataURI.split(",")[1]);

            // separate out the mime component
            //var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            var bb = new Blob([ab], { type: type });
            return bb;
        },

        saveTemplate(email) {
            if (
                confirm(
                    "Do you want to save the artwork? Saving artwork means you can proceed with adding the product to the cart. Your order will be created with the files you uploaded."
                )
            ) {
                var sendArr = new Object();
                sendArr.project = this.project;
                console.log(sendArr, "sendArr")

                let current_order_id =
                    localStorage.getItem("current_order_id") !== null
                        ? localStorage.getItem("current_order_id")
                        : "";
                let project_left_ratio = this.project.left_ratio != "" ? this.project.left_ratio : 0;
                let cart = this.project.cart != "" ? this.project.cart : "d";
                let template_id = this.project.template_id != "" ? this.project.template_id : "";
                let ratio = this.project.ratio != "" ? this.project.ratio : "";
                let rotate_margin_top = this.project.rotate_margin_top != "" ? this.project.rotate_margin_top : "";
                let rotate_margin_left =
                    this.project.rotate_margin_left != ""
                        ? this.project.rotate_margin_left
                        : "";
                let width = this.project.width != "" ? this.project.width : "";
                let height = this.project.height != "" ? this.project.height : "";
                let newwidth = this.project.newwidth != "" ? this.project.newwidth : "";
                let newheight =
                    this.project.newheight != "" ? this.project.newheight : "";
                let actual_width =
                    this.project.actual_width != "" ? this.project.actual_width : "";
                let actual_height =
                    this.project.actual_height != "" ? this.project.actual_height : "";
                let org_width =
                    this.project.org_width != "" ? this.project.org_width : "";
                let org_height =
                    this.project.org_height != "" ? this.project.org_height : "";

                let formData = new FormData();
                formData.append("current_order_id", current_order_id);
                formData.append("project[left_ratio]", project_left_ratio);
                formData.append("project[cart]", cart);
                formData.append("project[template_id]", template_id);
                formData.append("project[ratio]", ratio);
                formData.append("project[rotate_margin_top]", rotate_margin_top);
                formData.append("project[rotate_margin_left]", rotate_margin_left);

                formData.append("project[width]", width);
                formData.append("project[height]", height);
                formData.append("project[newwidth]", newwidth);
                formData.append("project[newheight]", newheight);
                formData.append("project[actual_width]", actual_width);
                formData.append("project[actual_height]", actual_height);
                formData.append("project[org_width]", org_width);
                formData.append("project[org_height]", org_height);
                console.log(this.project.front, "this.project.front");
                if (this.project.front) {
                    //if front part is there
                    formData.append(
                        "project[front][file][name]",
                        this.project.front.file.name !== ""
                            ? this.project.front.file.name
                            : ""
                    );
                    formData.append(
                        "project[front][file][width]",
                        this.project.front.file.width !== ""
                            ? this.project.front.file.width
                            : ""
                    );
                    formData.append(
                        "project[front][file][height]",
                        this.project.front.file.height !== ""
                            ? this.project.front.file.height
                            : ""
                    );
                    formData.append(
                        "project[front][file][scaled_width]",
                        this.project.scaled_width !== "" ? this.project.scaled_width : ""
                    );

                    formData.append(
                        "project[front][file][scaled_height]",
                        this.project.scaled_height !== "" ? this.project.scaled_height : ""
                    );
                    formData.append(
                        "project[front][file][result]",
                        this.project.front.result !== "" ? this.project.front.result : false
                    );
                    formData.append(
                        "project[front][file][is_special]:",
                        this.project.front.is_special !== ""
                            ? this.project.front.is_special
                            : ""
                    );
                    formData.append(
                        "project[front][file][positionx]",
                        this.project.front.file.positionx !== ""
                            ? this.project.front.file.positionx
                            : ""
                    );
                    formData.append(
                        "project[front][file][positiony]",
                        this.project.front.file.positiony !== ""
                            ? this.project.front.file.positiony
                            : ""
                    );
                    formData.append(
                        "project[front][file][scale]",
                        this.project.front.file.scale !== ""
                            ? this.project.front.file.scale
                            : ""
                    );
                    formData.append(
                        "project[front][file][rotate]",
                        this.project.front.rotate !== ""
                            ? this.project.front.file.rotate
                            : 0
                    );
                }

                $(".erroremail").hide();
                $(".successemail").hide();
                console.log(this.project, "project ddddd")
                if (email === "undefined" || email === " ") {
                    // this.saveArtwork(formData)
                    this.saveArtwork(sendArr)
                        .then((res) => {
                            console.log(res, "saveArtwork");
                            toast.success("Artwork saved successfully", { autoClose: 2400 });
                            $("#artwork-upld-link").hide();

                            //template-for-product
                            console.log("Artwork saved successfully..????", res.data);
                            $("#template-for-product").val(res.data.data.file);
                            document.getElementById("closeart").click();
                        }).catch((error) => {
                            console.log(error);
                        });
                } else {
                    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                    let checkemail = regex.test(email);
                    if (checkemail == false || email.length <= 0) {
                        $(".erroremail").show("slow");
                        $(".erroremail").html("Please enter a valid email address");
                    } else {
                        $(".erroremail").hide("slow");
                        $(".erroremail").html(" ");
                        sendArr.email = email;
                        this.saveArtwork(sendArr)
                            .then((res) => {
                                if (res.status == 200) {
                                    // This Code comment By Nadeem 
                                    //   if (localStorage.getItem("artworkFiles") == null) {
                                    //     let files = {
                                    //       file_name: res.data.file,
                                    //       file_path: res.data.path,
                                    //     };

                                    //     this.Artwork.files_name = res.data.file;
                                    //     this.Artwork.file_path = res.data.path;
                                    //     localStorage.setItem("artworkFiles", JSON.stringify(files));
                                    //   } else {
                                    //     let files = {
                                    //       file_name: res.data.file,
                                    //       file_path: res.data.path,
                                    //     };
                                    //     this.Artwork.files_name = res.data.file;
                                    //     this.Artwork.file_path = res.data.path;
                                    //     localStorage.setItem("artworkFiles", JSON.stringify(files));
                                    //   }
                                    document.getElementById("artworkclose").click();
                                    $(".successemail").show("slow");
                                    $(".successemail").html(
                                        "<span>Artwork mail sent successfully"
                                    );

                                    setTimeout(() => {
                                        $(".successemail").hide("slow");
                                        $(".successemail").html("");
                                        $("#artwork-upld-link").hide();
                                        $("#template-for-product").val(res.data.data.file);
                                        document.getElementById("closeart").click();
                                    }, 2000);
                                } else {
                                    $(".erroremail").show("slow");
                                    $(".erroremail").html(
                                        "<span>There is an error in sending email,please try again latter</span>"
                                    );
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                }
            }
        },

        saveTemplateEmail() {
            let hideemail = document.getElementById("email-details");
            if (hideemail.classList.contains("hidden")) {
                hideemail.classList.remove("hidden");
            } else {
                hideemail.classList.add("hidden");
            }
        },

    },

};
</script>

<style scoped>
a.btn {
    text-transform: uppercase !important;
    color: #fff !important;
}

a.btn:hover {
    border-radius: 0px 15px 0px 15px !important;
}

button.close {
    float: right;
    font-size: 21px !important;
    font-weight: 700;
    line-height: 1;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
    background: transparent !important;
    border: none;
}

button#closeart.close:hover {
    color: #000 !important;
}

#canvaspopup .modal-content {
    width: 1200px;
    right: 110px;
}

/* .main-artwork {
  margin-top: 244px;
  margin-left: 26px;
  width: 797px;
  height: 359px;
  position: relative;
} */
.tool-title {
    font-size: 11px;
    border-bottom: 1px solid #cccccc;
}

.tool-body {
    text-align: center;
    margin-top: 10px;
}

.br-rt {
    border-right: 1px solid #ccc;
    padding-right: 10px;
}

.marg10 {
    margin-left: 5px;
}

.pull-left {
    float: left !important;
}

.blk {
    display: inline-block;
}

.btn {
    font-weight: 400;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857143;
    border-radius: 2px;
    position: relative;
}

.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 6px 12px;
    width: 38px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

/****artwork css****/

.modal-body {
    overflow: hidden;
}

#workspace {
    height: 847.16px;
}

.modal-dialog {
    width: 90%;
    min-width: 1000px;
}

.template-bg {
    /* background: url("/img/transparency.png"); */
    position: absolute;
    z-index: 1;
    width: 797px;
    height: 359px;
}

/*#main-front{background:url('/img/transparency.png'); width: <?php echo round($main_width); ?>px}*/
.tool-title {
    font-size: 11px;
    border-bottom: 1px solid #cccccc;
}

.tool-body {
    text-align: center;
    margin-top: 10px;
}

/*.artwork-selection.selected{box-shadow:0 0 5px #005A84}*/
.rotate90 {
    transform: rotate(90deg);
}

.marg10 {
    margin-left: 5px;
}

.fileUpload {
    position: relative;
    overflow: hidden;
}

.blk {
    display: inline-block;
}

.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 6px 12px;
    width: 38px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.fileUpload input.upload:hover {
    border-radius: 0px 15px 0px 15px !important;
}

.user-artwork,
.template {
    position: absolute;
    left: 0;
    top: 0;
}

.user-artwork {
    z-index: 99998;
}

.user-artwork img {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.template {
    z-index: 99999;
}

.br-rt {
    border-right: 1px solid #ccc;
    padding-right: 10px;
}

.panel-style {
    position: relative;
    z-index: 200000;
    height: unset;
}

.progress-bar {
    background: #317eac;
    height: 10px;
}

.progress-percent {
    color: #317eac;
    font-size: 10px;
}

.art-lft {
    position: relative;
    height: 240px;
    overflow: hidden;
}

.artwork-selection {
    /*position:absolute;*/
    left: 0px;
    right: 0px;
    z-index: 999;
}

.artwork-selection a {
    width: 100%;
}

.upld-artwork-small {
    margin-top: 20px;
    /*position:absolute; left:0px; right: 0px; z-index:998; top:40px; */
}

.up-down {
    position: relative;
    margin-top: -10px;
}

.up-down i {
    display: block;
}

.upld-artwork-small img {
    /*height: 100%;
        overflow: hidden;
        transform-origin: 20% 40%;*/
    max-width: 100%;
}

#myModal1>div.modal-dialog>div>div.modal-body.clearfix {
    overflow: auto !important;
}

@media (max-width: 768px) {
    .modal-dialog {
        width: 90%;
        min-width: auto !important;
    }

    .main-artwork {
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
        height: auto;
        position: relative;
    }

    .template-bg {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: auto;
    }

    .user-artwork,
    .template {
        position: relative;
    }

    .user-artwork img,
    .template img {
        max-width: 100%;
    }

    .art-lft {
        position: relative;
        height: auto !important;
        overflow: visible;
    }
}

#send-email {
    background: #198754 !important;
    width: 76px !important;
    padding: 4px 4px 4px 4px !important;
    font-size: 1em !important;
    margin-left: -50px !important;
    margin-top: 6px !important;
    color: #fff !important;
}

.artwrk.modal-title {
    color: #3333339e !important;
    text-shadow: 2px 2px #4950571c;
}

.upload-progress {
    text-align: center !important;
}

.panel.anel-default.panel-style {
    left: 40px !important;
}</style>
